export default [
    '🌿',
    '😘',
    '🤩',
    '🥰',
    '👍',
    '😂',
    '😊',
    '🔥',
    '❤️',
    '🏁',
    '✨',
    '🚣',
    '💍',
    '🎓',
    '👑',
    '🕶️',
    '☂️',
    '👄',
    '👀',
    '😽',
    '👻',
    '👽',
    '🤪',
    '🤔',
    '😇',
    '😅',
    '💥',
    '💫',
    '🦁',
    '🦄',
    '🐪',
    '🐻‍❄️',
    '🐔',
    '🐧',
    '🐸',
    '🐳',
    '🦋',
    '🐝',
    '🌸',
    '🌻',
    '🌳',
    '🍄',
    '🌎',
    '🌞',
    '⭐',
    '☁️',
    '🌈',
    '❄️',
    '🙃',
    '😉',
    '🤫',
    '🤠',
    '🥳',
    '😮',
    '🤡',
    '👍',
    '🔑',
    '📈',
    '💰',
    '📺',
    '☎️',
    '🖼️',
    '🧸',
    '🪁',
    '🎁',
    '🎎',
    '🎈',
    '🎉',
    '⏰',
    '⏳',
    '💌',
    '💎',
    '💡',
    '🚀',
    '🚁',
    '✈️',
    '🛣️',
    '⚓',
    '🌃',
    '🌅',
    '🏠',
    '🚓',
    '🚕',
    '🚜',
    '🛸',
    '🗼',
    '🧊',
    '🍸',
    '🍷',
    '🍼',
    '🍭',
    '🍬',
    '🎂',
    '🍥',
    '🍿',
    '🥝',
    '🦁',
    '🍓',
    '🍒',
    '🍏',
    '🍎',
    '🍍',
    '🍉',
    '🎵',
    '🔔',
    '💯',
    '💚',
    '💛',
    '🏄‍♀️',
    '🏆',
    '🏀',
    '⚽',
    '🏈',
    '🎾',
    '🏓',
    '🎯',
    '🎲',
    '🧩',
    '🎮',
    '🎨',
    '🎭',
    '🎷',
    '🪗',
    '🎸',
    '🎹',
    '🎺',
    '🎻',
    '🥁',
    '🎬',
    '🎰',
    '⛳',
    '🤣',
    '😁',
    '😀',
    '🤨',
    '✔️',
    '🧵',
    '🧦',
    '😎',
    '👌',
    '💁‍♂️',
    '🤦‍♀️',
    '👩‍💼',
    '🧑‍🔬',
    '👮‍♂️',
    '💂‍♂️',
    '🦸‍♀️',
    '🧜‍♀️',
    '💃',
    '🕺',
    '🦊',
    '⚠️',
    '📣',
    '💙',
    '💝',
    '💕',
    '📶',
];
