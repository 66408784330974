import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import * as selectors from '../../redux/selectors';

import { routes, routeHomePage } from '../../../config';
import { NavLink } from 'react-router-dom';
import SidebarMenuItem from '../SidebarMenuItem';

const SidebarMenu = () => {
    const currentLocale = useSelector((state: RootState) =>
        selectors.selectLocale(state)
    );
    const isMenuOpen = useSelector((state: RootState) =>
        selectors.selectIsMobileMenuOpen(state)
    );

    return (
        <div className={`sidebar${isMenuOpen ? ' sidebar-hidden' : ''}`}>
            <div
                className="sidebar-menu"
                data-cy="sidebar-menu"
                itemScope /* itemtype="http://schema.org/BreadcrumbList" */
            >
                <NavLink
                    to={currentLocale === 'en' ? routeHomePage.path : ''}
                    key={`SidebarMenuItem___home`}
                >
                    <SidebarMenuItem
                        icon={routeHomePage.icon}
                        serial={routeHomePage.serial}
                    />
                </NavLink>

                {routes.map(
                    (route) =>
                        route.icon && (
                            <NavLink
                                to={route.path}
                                key={`SidebarMenuItem___${route.title}`}
                            >
                                <SidebarMenuItem
                                    icon={route.icon}
                                    serial={route.serial}
                                />
                            </NavLink>
                        )
                )}
            </div>
        </div>
    );
};
export default SidebarMenu;
