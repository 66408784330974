import { useState } from 'react';
// import SidebarMenuSub from '../SidebarMenuSub';

type SidebarMenuItemProps = {
    serial: string;
    icon: any;
};

const SidebarMenuItem = ({ icon, serial }: SidebarMenuItemProps) => {
    const [showSubMenu, setShowSubMenu] = useState(false);

    return (
        <div
            className={`sidebar-menu-item ${
                showSubMenu ? 'sub-menu-open' : ''
            }`}
            onMouseEnter={() => setShowSubMenu(true)}
            onMouseLeave={() => setShowSubMenu(false)}
            onClick={() => {
                setShowSubMenu(false);
            }}
        >
            <div className="sidebar-menu-item-icon">{icon}</div>
            {/* {showSubMenu && mockup && (
                <SidebarMenuSub mockup={mockup} serial={serial} />
            )} */}
        </div>
    );
};

export default SidebarMenuItem;
