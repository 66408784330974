import { useDispatch, useSelector } from 'react-redux';
import { actionFinishPlaying } from '../../redux/actions';
import { RootState } from '../../../redux/store';
import StopCircleIcon from '@material-ui/icons/Stop';

const ButtonStopGame = () => {
    const dispatch = useDispatch();
    const isPlaying = useSelector(
        (state: RootState) => state.reducerM2.isPlaying
    );
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <button
            className={`button-stop-game ${
                isPlaying ? ' currently-playing' : ' not-playing'
            }`}
            onClick={() => dispatch(actionFinishPlaying())}
            disabled={!isPlaying}
        >
            <StopCircleIcon className="stop-game-icon" />
        </button>
    );
};

export default ButtonStopGame;
