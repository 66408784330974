import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionUserPressesNextNumberButton } from '../../redux/actions';
import { RootState } from '../../../redux/store';
import * as constants from '../../redux/constants';
import * as selectors from '../../redux/selectors';
import * as selectorsApp from '../../../App/redux/selectors';
import { translate } from '../../i18n';
import Typography from '@material-ui/core/Typography';
import RepeatIcon from '@material-ui/icons/Repeat';

const ButtonNextNumber = () => {
    const dispatch = useDispatch();
    const gameStage = useSelector((state: RootState) =>
        selectors.selectGameStage(state)
    );
    const locale = useSelector((state: RootState) =>
        selectorsApp.selectLocale(state)
    );

    const isDisabled = useMemo(
        () => gameStage !== constants.STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
        [gameStage]
    );

    return (
        <div className="next-number">
            <button
                onClick={() => {
                    dispatch(actionUserPressesNextNumberButton());
                }}
                className={`next-number-button${
                    isDisabled ? ' game-element-not-active' : ''
                }`}
                disabled={isDisabled}
            >
                <RepeatIcon />

                <Typography variant="button" display="block" gutterBottom>
                    {translate[locale]?.next}
                </Typography>
            </button>
        </div>
    );
};

export default ButtonNextNumber;
