import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import reducer from '../index';
import { middlewareM2 } from '../../M2/redux/middleware';

// const loggerMiddleware = createLogger();
export const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(middlewareM2))
);

export type RootState = ReturnType<typeof store.getState>;
