import HomeIcon from '@material-ui/icons/Home';
// import GamepadIcon from '@material-ui/icons/Gamepad';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import HelpCenterIcon from '@material-ui/icons/Help';
import FeaturedPlayList from '@material-ui/icons/FeaturedPlayList';

// import M1 from '../M1';
import M2 from '../M2';
import M3 from '../M3';
import FAQ from '../FAQ';
import FeaturedGames from '../FeaturedGames';

import MockupM2 from '../M2/components/Mockup';

import App from '../App';

interface _RouteData {
    icon: any;
    path: string;
    serial?: string;
    title?: string;
}

interface _RouteDataModule extends _RouteData {
    main: any;
}

interface RouteData {
    title: string;
    serial: string;
    path: string;
    main: any;
    icon?: any;
}

interface MetaData {
    title: string;
    domain: string;
}

const routesGlobalLocale: Required<_RouteDataModule>[] = [
    {
        title: 'BrainSquatting.com',
        serial: '',
        path: '/',
        main: () => <App />,
        icon: <HomeIcon fontSize="large" />,
    },
    {
        title: 'BrainSquatting.com',
        serial: '',
        path: '/:lang',
        main: () => <App />,
        icon: <HomeIcon fontSize="large" />,
    },
    // {
    //     title: 'BrainSquatting.com',
    //     serial: '',
    //     path: '*',
    //     main: () => <App />,
    //     icon: <HomeIcon fontSize="large" />,
    // },
];

const routeHomePage: Required<_RouteData> = {
    path: '/',
    icon: <HomeIcon fontSize="large" />,
    serial: '',
    title: 'Home',
};

const routes: Required<_RouteDataModule>[] = [
    {
        title: 'M2',
        serial: 'm2',
        path: '',
        main: () => <M2 />,
        icon: null,
    },
    {
        title: 'Featured Games',
        serial: 'm2',
        path: 'games',
        main: () => <M2 />,
        icon: <FeaturedPlayList fontSize="large" />,
    },
    {
        title: 'M2',
        serial: 'm2',
        path: 'games/m2',
        main: () => <M2 />,
        icon: <VideogameAssetIcon fontSize="large" />,
    },
    {
        title: 'FAQ',
        serial: 'faq',
        path: 'faq',
        main: () => <FAQ />,
        icon: <HelpCenterIcon fontSize="large" />,
    },
    {
        title: 'M3',
        serial: 'm3',
        path: 'games/m3',
        main: () => <M3 />,
        icon: <VideogameAssetIcon fontSize="large" />,
    },
];

const meta: Required<MetaData> = {
    title: 'BrainSquatting',
    domain: 'BrainSquatting.com',
};

export { routeHomePage, routesGlobalLocale, routes, meta };
