import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
// import GaugeChart from 'react-gauge-chart';

import { translate } from '../../i18n';

// import { HistoryIcon, CheckCircleOutlineIcon, CheckCircleIcon } from '@material-ui/icons';
// import { HistoryIcon, CheckCircleOutlineIcon, CheckCircleIcon } from '@material-ui/icons';
import History from '@material-ui/icons/History';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SpeedIcon from '@mui/icons-material/Speed';
const GameScore = () => {
    const points: number = useSelector(
        (state: RootState) => state.reducerM2.points
    );
    const gamesPlayed = useSelector(
        (state: RootState) => state.reducerM2.gamesPlayed
    );
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );
    const [successRate, setSuccessRate] = useState<number>(0);
    const calculateSuccessRate = (
        points: number,
        gamesPlayed: number
    ): number => {
        if (isNaN(points / gamesPlayed) || points / gamesPlayed === Infinity)
            return 0;
        return 100 * (points / gamesPlayed);
    };
    useEffect(() => {
        setSuccessRate(calculateSuccessRate(points, gamesPlayed));
    }, [gamesPlayed, points]);

    return (
        <div className="layout-component-wrapper game-score">
            <span className="game-score-wrapper">
                <div className="game-score-section">
                    {/* <div className="game-score-category">
                        <Typography variant="overline" component="p">
                            {translate[locale]?.score_games_played}:
                        </Typography>
                    </div> */}
                    <div className="game-score-value">
                        <History />

                        <Typography variant="overline" component="p">
                            {gamesPlayed}
                        </Typography>
                    </div>
                </div>
                <div className="game-score-section">
                    {/* <div className="game-score-category">
                        <Typography variant="overline" component="p">
                            {translate[locale]?.score_correct_answers}:
                        </Typography>
                    </div> */}
                    <div className="game-score-value">
                        <EmojiEventsIcon />

                        <Typography variant="overline" component="p">
                            {points}
                        </Typography>
                    </div>
                </div>
                <div className="game-score-section">
                    {/* <div className="game-score-category"> */}
                    {/* <Typography variant="overline" component="p">
                            {translate[locale]?.score_success_rate}:
                        </Typography> */}
                    {/* </div> */}
                    {/* <hr /> */}
                    <div className="game-score-value">
                        <SpeedIcon />
                        <Typography variant="overline" component="p">
                            {`${successRate.toFixed(2)}%`}
                        </Typography>

                        {/* <GaugeChart
                            id="gauge-chart2"
                            nrOfLevels={20}
                            percent={successRate}
                        /> */}
                    </div>
                </div>
            </span>
        </div>
    );
};

export default GameScore;
