import { useSelector, useDispatch } from 'react-redux';
import { translate } from '../../i18n';
import {
    actionToggleSettings,
    actionFinishPlaying,
    actionToggleHowToPlay,
} from '../../redux/actions';
import { RootState } from '../../../redux/store';

import SettingsIcon from '@material-ui/icons/Settings';

import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GameScoreTop from '../GameScoreTop';
const GameTitle = () => {
    const locale = useSelector((state: RootState) => state.reducerApp.locale);
    const dispatch = useDispatch();
    const isSettingsOpen = useSelector(
        (state: RootState) => state.reducerM2.isSettingsOpen
    );
    return (
        <div className="layout-component-wrapper game-title">
            <Typography
                className="_model-title"
                variant="overline"
                component="h1"
            >
                {translate[locale]?.title}
            </Typography>

            {/* <Typography
                className="how-to-play-link-text"
                variant="overline"
                display="block"
            >
                {translate[locale]?.how_to_play}
                <HelpOutlineIcon />
            </Typography> */}
            {/* <GameScoreTop /> */}
            <button
                className="game-settings-button"
                onClick={(e) => {
                    dispatch(
                        actionToggleHowToPlay({
                            isOpen: true,
                        })
                    );
                }}
            >
                <HelpOutlineIcon fontSize="small" />
            </button>

            <button
                className="game-settings-button"
                onClick={(e) => {
                    dispatch(actionFinishPlaying());

                    dispatch(
                        actionToggleSettings({
                            isOpen: !isSettingsOpen,
                        })
                    );
                }}
            >
                <SettingsIcon fontSize="small" />
            </button>
        </div>
    );
};

export default GameTitle;

/**
 * 
 * 
 * 
 *         <div
                className="how-to-play-link"
                onClick={() => {
                    dispatch(
                        actionToggleHowToPlay({
                            isOpen: true,
                        })
                    );
                }}
            >
                <Typography
                    className="how-to-play-link-text"
                    variant="overline"
                    display="block"
                >
          {translate[locale]?.how_to_play} 
                    <HelpOutlineIcon />
                </Typography>
            </div>

 */
