import { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionChangeLocale } from './redux/actions';
import Header from './components/Header';
import Footer from './components/Footer';
import SidebarMenu from './components/SidebarMenuNew';
import MobileMenu from './components/MobileMenu';

import Banner from './components/Banner';
import Banner2 from './components/Banner2';

import AdBottom from './components/AdBottom';

// import Animation from './components/Animation';

import AppSEO from './seo';
import './app.styles.css';

const App = () => {
    const dispatch = useDispatch();
    const sidebarOpen = useSelector(
        (state: any) => state.reducerApp.isMobileMenuOpen
    );

    const isDarkTheme = useSelector(
        (state: any) => state.reducerApp.isDarkTheme
    );
    const { lang } = useParams();

    useEffect(() => {
        lang
            ? dispatch(actionChangeLocale({ locale: lang }))
            : dispatch(actionChangeLocale({ locale: 'en' }));
    }, [lang, dispatch]);

    return (
        <div className={`wrapper${isDarkTheme ? ' dark-theme-mode' : ''}`}>
            <AppSEO />
            <Header />
            <Banner2 />
            <div className="main">
                <Outlet />
            </div>
            <AdBottom />
            <Footer />
            <SidebarMenu />
            {sidebarOpen && <MobileMenu />}
        </div>
    );
};

export default App;
