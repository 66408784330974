import { useSelector } from 'react-redux';
import { translate } from './i18n';
import Typography from '@material-ui/core/Typography';

const FAQ = () => {
    const locale: any = useSelector((state: any) => state.reducerApp.locale);

    return (
        <div className="wrapper-model wrapper-faq">
            <Typography variant="h5" gutterBottom component="div">
                {translate[locale].q_01}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {translate[locale].a_01}
            </Typography>
            <hr />

            <Typography variant="h5" gutterBottom component="div">
                {translate[locale].q_02}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {translate[locale].a_02}
            </Typography>
            <hr />

            <Typography variant="h5" gutterBottom component="div">
                {translate[locale].q_03}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {translate[locale].a_03}
            </Typography>
            <hr />

            <Typography variant="h5" gutterBottom component="div">
                {translate[locale].q_04}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {translate[locale].a_04}
            </Typography>
            <hr />

            <Typography variant="h5" gutterBottom component="div">
                {translate[locale].q_05}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {translate[locale].a_05}
            </Typography>
            <hr />

            <Typography variant="h5" gutterBottom component="div">
                {translate[locale].q_06}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {translate[locale].a_06}
            </Typography>
            <hr />

            <Typography variant="h5" gutterBottom component="div">
                {translate[locale].q_07}
            </Typography>

            <Typography variant="body1" gutterBottom>
                {translate[locale].a_07}
            </Typography>
            <hr />
        </div>
    );
};

export default FAQ;
