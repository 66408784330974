/**
 *
 * social FA WhatsappA share
 * open graph i
 * cypress
 * lerna
 * if time is out, game should move to next level and not wait until user press skip
 */

import React from 'react';
import './m2.styles.css';
import GameSettings from './components/GameSettings';
import GameBoard from './components/GameBoard';
import GameControls from './components/GameControls';
import GameTitle from './components/GameTitle';
import GameScore from './components/GameScore';
import HowToPlay from './components/HowToPlay';
import SEO from './seo';

const M2 = () => {
    return (
        <React.Fragment>
            <SEO />
            <div className="wrapper-model wrapper-m2">
                <GameTitle />
                <GameScore />
                <GameControls />
                <GameBoard />
                <GameSettings /> {/** modal */}
                <HowToPlay /> {/** modal */}
            </div>
        </React.Fragment>
    );
};

export default M2;
