import { Emoji } from '../types';
import emojiArr from '../emojis';

const emojiArrLength = emojiArr.length;

const primeNumbers = [3, 5, 7, 11, 13];

const shuffle = (array: any[]) => {
    const array_length = array.length;

    for (let i = 0; i < array.length; i++) {
        // Generate random number
        const randomIndex = Math.floor(Math.random() * array_length);
        // And swap it with the current element
        [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    }

    return array;
};

const generateRandomPrimeNumber = () =>
    primeNumbers[Math.floor(Math.random() * primeNumbers.length)];

const generateStartingPoint = () => Math.floor(Math.random() * emojiArr.length);

const generateNumberOfEmojiShown = (numOfGridUnits: number) =>
    Math.floor((numOfGridUnits * numOfGridUnits) / 2);

const createRandomEmojiArray = (numOfGridUnits: number) => {
    const randomEmojiArray = [];
    const numberOfEmojiShown = generateNumberOfEmojiShown(numOfGridUnits);
    let currentIndex = generateStartingPoint();
    const primeNumber = generateRandomPrimeNumber();

    for (let i = 0; i < numberOfEmojiShown; i++) {
        randomEmojiArray.push(emojiArr[currentIndex]);
        randomEmojiArray.push(emojiArr[currentIndex]);
        // //console.log('loop: _emojiArr: ', _emojiArr);

        currentIndex =
            currentIndex + primeNumber <= emojiArrLength - 1
                ? currentIndex + primeNumber
                : currentIndex + primeNumber - emojiArrLength;
    }
    return randomEmojiArray;
};

export const generateRandomShuffledEmojiArray = (numOfGridUnits: number) => {
    // const numberOfEmojiShown = generateNumberOfEmojiShown(numOfGridUnits);
    const randomEmojiArray = createRandomEmojiArray(numOfGridUnits);
    shuffle(randomEmojiArray);
    return randomEmojiArray;
};

/***************************/
/** dynamic grid cells  */
/***************************/
/**
 *
 * Array length is odd
 */
/**
 *
 * let arrayEmoji
 * let arrayEmojiLength
 * let primeNumber
 * let startingPoint
 * let numberOfCells
 * let arrayEmoji_2
 * let _index = startingPoint
 * while arrayEmoji_2 length <= N/2 do:
 *  (@)
 *  arrayEmoji_2.push(arrayEmoji[_index])
 *  if: (_index + primeNumber <= arrayEmojiLength)
 *      then: _index = _index + primeNumber
 *      else: _index = _index + primeNumber - arrayEmojiLength
 *  go to step (@)
 *
 *  (proove that no repeating emojis)
 */
