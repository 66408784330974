import ButtonOpenNumber from '../ButtonOpenNumber';
import ButtonCloseNumber from '../ButtonCloseNumber';
import SkipAndNextNumber from '../SkipAndNextNumber';
import NumberUserInput from '../NumberUserInput';
import NumberFromComputer from '../NumberFromComputer';

const GameBoard = () => {
    return (
        <div className="layout-component-wrapper game-board-component-wrapper">
            <div className="game-board-component-internal">
                <ButtonCloseNumber />
                <ButtonOpenNumber />
                <SkipAndNextNumber />
                <NumberUserInput />
                <NumberFromComputer />
            </div>
        </div>
    );
};

export default GameBoard;
