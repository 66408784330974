import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCloseNumberFromComputer } from '../../redux/actions';
import * as constants from '../../redux/constants';
import * as selectors from '../../redux/selectors';
import { RootState } from '../../../redux/store';
import * as selectorsApp from '../../../App/redux/selectors';
import { translate } from '../../i18n';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';

const ButtonCloseNumber = () => {
    const dispatch = useDispatch();

    const locale = useSelector((state: RootState) =>
        selectorsApp.selectLocale(state)
    );
    const gameStage = useSelector((state: RootState) =>
        selectors.selectGameStage(state)
    );

    const isDisabled = useMemo(
        () => gameStage !== constants.STAGE_01_NUMBER_FROM_COMPUTER_OPEN,
        [gameStage]
    );

    return (
        <div className="close-number">
            <button
                onClick={() => {
                    dispatch(actionCloseNumberFromComputer());
                }}
                className={`close-number-button${
                    isDisabled ? ' game-element-not-active' : ''
                }`}
                disabled={isDisabled}
            >
                <LockIcon />
                <Typography variant="button" display="block" gutterBottom>
                    {translate[locale]?.close_number}
                </Typography>
            </button>
        </div>
    );
};

export default ButtonCloseNumber;
