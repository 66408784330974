import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { translate } from '../i18n'; //note: global translate
import { meta } from '../../config';
const AppSEO = () => {
    const locale = useSelector((state: any) => state.reducerApp.locale);

    return (
        <Helmet>
            <title>{`${meta.title} | ${translate[locale]?.main.title}`}</title>
            <meta
                name="description"
                content={`${meta.title}  | ${translate[locale]?.main.description}`}
            />
            <meta
                property="og:description"
                content={`${meta.title}  | ${translate[locale]?.main.description}`}
            />
            <meta
                property="og:title"
                content={`${meta.title}  | ${translate[locale]?.main.title}`}
            />
            <meta
                name="twitter:description"
                content={`${meta.title}  | ${translate[locale]?.main.description}`}
            />
            <meta
                name="twitter:title"
                content={`${meta.title}  | ${translate[locale]?.main.title}`}
            />
            <meta
                http-equiv="content-language"
                content={translate[locale]?.bcp47}
            />
        </Helmet>
    );
};

export default AppSEO;
