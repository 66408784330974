import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import MenuIcon from '@material-ui/icons/Menu';

const HeaderMobileMenuButton = () => {
    const dispatch = useDispatch();

    return (
        <div className="mobile-menu-button" data-cy="mobile-menu-button">
            <button
                onClick={() => {
                    dispatch(actions.actionTogleMenuButton());
                }}
                style={{
                    border: 'none',
                    padding: 0,
                    background: 'transparent',
                    display: 'flex',
                    cursor: 'pointer',
                }}
            >
                <MenuIcon className="mobile-menu-button-icon" />
            </button>
        </div>
    );
};

export default HeaderMobileMenuButton;
