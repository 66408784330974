import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { translate } from '../../App/i18n'; //note: global translate

const SEO = () => {
    const locale = useSelector((state: RootState) => state.reducerApp.locale);

    return (
        <Helmet>
            <title>{`BrainSquatting | ${translate[locale]?.m2.title}`}</title>
            <meta
                name="description"
                content={translate[locale]?.m2.description}
            />

            <meta
                property="og:title"
                content={`BrainSquatting | ${translate[locale]?.m2.title}`}
            />
            <meta
                property="og:description"
                content={translate[locale]?.m2.description}
            />

            <meta
                name="twitter:title"
                content={`BrainSquatting | ${translate[locale]?.m2.title}`}
            />
            <meta
                name="twitter:description"
                content={translate[locale]?.m2.description}
            />
        </Helmet>
    );
};

export default SEO;
