import { useDispatch, useSelector } from 'react-redux';
import { actionPause } from '../../redux/actions';
import { RootState } from '../../../redux/store';
import PauseCircleIcon from '@material-ui/icons/Pause';

const ButtonPauseGame = () => {
    const dispatch = useDispatch();
    const isPaused = useSelector(
        (state: RootState) => state.reducerM2.isPaused
    );
    const isPlaying = useSelector(
        (state: RootState) => state.reducerM2.isPlaying
    );

    return (
        <button
            onClick={() => dispatch(actionPause({ isPaused: !isPaused }))}
            className={`button-pause-game${isPaused ? ' is-paused' : ''}${
                isPlaying ? ' currently-playing' : ' not-playing'
            }`}
            disabled={!isPlaying}
        >
            <PauseCircleIcon className="pause-game-icon" />
        </button>
    );
};

export default ButtonPauseGame;
