import React, { SetStateAction, useState, useEffect } from 'react';
import './m3.index.css';
import Cell from './components/Cell';
import CellN from './components/CellN';

import Typography from '@material-ui/core/Typography';

import { Emoji, EmojiNew } from './types';
import { generateRandomShuffledEmojiArray } from './helpers';
import emojis from './emojis';

const M3 = () => {
    const [numOfGridUnits, setNumOfGridUnits] = useState(8);
    const [gameGridStyle, setGameGridStyle] = useState({
        background: 'violet',
        gridArea: 'game-grid',
        display: 'grid',
        gridTemplateRows: `repeat(${numOfGridUnits}, 1fr)`,
        gridTemplateColumns: `repeat(${numOfGridUnits}, 1fr)`,
        gap: '1px',
        padding: '1px',
    });
    const [selectedEmoji, setSelectedEmoji] = useState<Emoji>({
        picture: null,
        data: null,
    });

    const [selectedEmojiNew, setSelectedEmojiNew] = useState<EmojiNew>({
        picture: '',
        dataAtt: '',
        isOpen: false,
    });

    const [points, setPoints] = useState<number>(0);
    const [emojis, setEmojis] = useState<any[]>([]);
    const [emojisF, setEmojisF] = useState<any[]>([]);

    useEffect(() => {
        setGameGridStyle({
            background: 'violet',
            gridArea: 'game-grid',
            display: 'grid',
            gridTemplateRows: `repeat(${numOfGridUnits}, 1fr)`,
            gridTemplateColumns: `repeat(${numOfGridUnits}, 1fr)`,
            gap: '5px',
            padding: '5px',
        });
        setEmojis(generateRandomShuffledEmojiArray(numOfGridUnits));

        let rm = emojis.map((emoji, index) => {
            return { picture: emoji, dataAtt: `emoji_${index}` };
        });
    }, [numOfGridUnits]);

    useEffect(() => {
        setEmojisF(
            emojis.map((emoji, index) => {
                return {
                    picture: emoji,
                    dataAtt: `emoji_${index}`,
                    isOpen: false /** WRONG - it is should be immutible if uing "global" state; while  */,
                };
            })
        );
    }, [emojis]);

    const handleClickOnEmojiNew = (emoji: EmojiNew) => {
        //console.log('handleClickOnEmojiNew: ', emoji);
        emoji.isOpen = true;
        //console.log('handleClickOnEmojiNew: later', emoji);
        document
            .querySelector(`[data-emoji='${emoji.dataAtt}']`)
            ?.classList.add('emoji-open');
        openOne(emoji);

        if (!selectedEmojiNew.picture) {
            setSelectedEmojiNew(emoji);
        } else {
            // same pic different data attr AKA "correct"
            if (
                emoji.picture === selectedEmojiNew.picture &&
                emoji.dataAtt != selectedEmojiNew.dataAtt
            ) {
                // correct answer recieves a point
                setPoints(points + 1);
                document
                    .querySelector(`[data-emoji='${emoji.dataAtt}']`)
                    ?.classList.add('emoji-open');
                document
                    .querySelector(`[data-emoji='${selectedEmoji.data}']`)
                    ?.classList.add('emoji-open');
            } else {
                // AKA "not correct"
                closeOne(selectedEmojiNew);
                closeOne(emoji);
            }
            // reset
            setSelectedEmojiNew({
                picture: '',
                dataAtt: '',
                isOpen: false,
            });
        }
    };
    const openAll = () => {
        //console.log('openAll: ');
        setEmojisF(
            emojisF.map((emojiF) => {
                return { ...emojiF, isOpen: true };
            })
        );
    };
    const closeAll = () => {
        //console.log('openAll: ');
        setEmojisF(
            emojisF.map((emojiF) => {
                return { ...emojiF, isOpen: false };
            })
        );
    };
    const openOne = (emoji: EmojiNew) => {
        setEmojisF(
            emojisF.map((emojiF) => {
                if (emojiF.dataAtt === emoji.dataAtt)
                    return { ...emojiF, isOpen: true };
                return { ...emojiF };
            })
        );
    };

    const closeOne = (emoji: EmojiNew) => {
        setEmojisF(
            emojisF.map((emojiF) => {
                if (emojiF.dataAtt === emoji.dataAtt)
                    return { ...emojiF, isOpen: false };
                return { ...emojiF };
            })
        );
    };

    return (
        <div className="wrapper-model wrapper-m3">
            <div className="game-control-m3">
                <div style={{ color: 'white' }}>points: {points}</div>
                <input
                    type="number"
                    value={numOfGridUnits}
                    onChange={(e) => {
                        setNumOfGridUnits(parseInt(e.target.value));
                        //console.log(e.target.value);
                    }}
                />
                <button
                    onClick={() => {
                        setEmojis(
                            generateRandomShuffledEmojiArray(numOfGridUnits)
                        );
                        setPoints(0);
                    }}
                >
                    Reset
                </button>
                <button
                    onClick={() => {
                        //console.log('emojis: ', emojis);
                        //console.log('emojisF: ', emojisF);
                    }}
                >
                    _debug
                </button>

                <button onClick={openAll}>open all</button>
                <button onClick={closeAll}>close all</button>

                <button
                    onClick={() =>
                        setEmojis(
                            generateRandomShuffledEmojiArray(numOfGridUnits)
                        )
                    }
                >
                    shuffle
                </button>
            </div>
            <div className="game-board-m3">
                <div className="game-grid-ma3" style={gameGridStyle}>
                    {emojisF.map((emoji, index) => (
                        <CellN
                            key={index}
                            handleClickOnEmojiNew={handleClickOnEmojiNew}
                            emoji={emoji}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default M3;
