import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { translate } from '../../i18n';
// import PlayArrow from '@material-ui/icons/PlayArrow';

/** REMINDER:
 *  THERE ARE SEVERAL STAGES IN THE GAME;
 *  MOVING TO DIFFERENT STAGE CAUSED BY USERS ACTIONS (OR TIMEOUT)
 */
import {
    STAGE_01_NUMBER_FROM_COMPUTER_OPEN,
    STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
    STAGE_03_USER_INPUT_OPEN,
    STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
} from '../../redux/constants';
import Typography from '@material-ui/core/Typography';

const GameSystemMessage = () => {
    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );
    const isPlaying = useSelector(
        (state: RootState) => state.reducerM2.isPlaying
    );
    const isCorrect = useSelector(
        (state: RootState) => state.reducerM2.isCorrect
    );
    const [dsecription, setDescription] = useState(
        'something will happen in...'
    );
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    useEffect(() => {
        const getMessage = () => {
            switch (gameStage) {
                case STAGE_01_NUMBER_FROM_COMPUTER_OPEN:
                    return translate[locale]?.notification_stage_01;
                case STAGE_02_NUMBER_FROM_COMPUTER_CLOSED:
                    return translate[locale]?.notification_stage_02;
                case STAGE_03_USER_INPUT_OPEN:
                    return translate[locale]?.notification_stage_03;
                case STAGE_04_COMPARE_RESULT_AND_FINISH_GAME:
                    return translate[locale]?.notification_stage_04;
                default:
                    return translate[locale]?.notification_default;
            }
        };

        setDescription(getMessage());
    }, [gameStage, locale]);

    return (
        <React.Fragment>
            <Typography
                data-cy="game-notification-message"
                variant="button"
                display="block"
                component="div"
            >
                {isCorrect ? (
                    <div>Correct Answer!</div>
                ) : (
                    <div id="text-with-icon">
                        <div
                            className={`system-message-description${
                                !isPlaying ? ' not-playing' : ''
                            }`}
                        >
                            {dsecription}
                        </div>
                        {/* {!isPlaying && <PlayArrow />} */}
                    </div>
                )}
            </Typography>
        </React.Fragment>
    );
};

export default GameSystemMessage;
