interface I18nScope {
    press_start_to_begin: string;
    play_now: string;
    playing: string;
    score_games_played: string;
    score_correct_answers: string;
    score_success_rate: string;
    score_strength_of_sequence: string;
    close_number: string;
    enter_number: string;
    skip: string;
    next: string;
    settings_title: string;
    settings_auto_increment: string;
    settings_auto_move_to_next_game: string;
    settings_length_of_sequence: string;
    settings_apply_time_limit: string;
    settings_save: string;
    settings_close: string;
    settings_saved: string;
    settings_enter_positive_number: string;
    tutorial_01: string;
    tutorial_02: string;
    tutorial_03: string;
    tutorial_04: string;
    tutorial_05: string;
    tutorial_06: string;
    tutorial_07: string;
    tutorial_08: string;
    tutorial_09: string;
    tutorial_10: string;
    tutorial_11: string;
    tutorial_12: string;
    tutorial_13: string;
    tutorial_next: string;
    tutorial_go_back: string;
    notification_default: string;
    notification_stage_01: string;
    notification_stage_02: string;
    notification_stage_03: string;
    notification_stage_04: string;
    number_from_computer_user_instructions: string;
    user_input_user_instructions: string;
    title: string;
    description: string;
    game_statistics: string;
    stop: string;
    pause: string;
    paused: string;
    how_to_play: string;
    how_to_play_details: string;
    how_to_play_close: string;
}

const en: Required<I18nScope> = {
    press_start_to_begin: 'Press to Begin Playing!',
    play_now: 'Start!',
    playing: 'Playing!',
    score_games_played: 'Games',
    score_correct_answers: 'Correct',
    score_success_rate: 'Rate',
    score_strength_of_sequence: 'Sequence Strength',
    close_number: 'Close Number',
    enter_number: 'Enter Number',
    skip: 'Skip',
    next: 'Next',
    settings_title: 'Game Settings',
    settings_auto_increment: 'Automatically increment length of sequence',
    settings_auto_move_to_next_game: 'Automatically move to next game',
    settings_length_of_sequence: 'Length of sequence',
    settings_apply_time_limit: 'Apply time limit (sec)',
    settings_save: 'Save Settings',
    settings_close: 'Close Settings',
    settings_saved: 'New Settings Saved!',
    settings_enter_positive_number: 'Please enter positive number',
    tutorial_01:
        'In this game, your goal is to memorize a series of random numbers. Press the Start button to begin...',
    tutorial_02:
        'The computer will create a random series of numbers. Try to memoize this series of numbers.',
    tutorial_03:
        'Once you finished memoizing the number, press the Close button and the number will disappear...',
    tutorial_04:
        'After you memoized the numbers, press here and type those numbers...',
    tutorial_05:
        'Type here the series of numbers which you memoized. (Hint: 1,2,3,4,5)',
    tutorial_06:
        'If you can not remember the number, you can press the skip button and try your luck with a new number.',
    tutorial_07:
        'You can change the way this game is played, press Settings button to see how:',
    tutorial_08:
        'If you do not want to move automatically to the next series of numbers after each game, select this option and you will be able to move manually after pressing Next button.',
    tutorial_09:
        'When you start playing, the length of the sequence starts from 4 numbers and increases gradually. If you want to start with different length and/or do not want the length to increase, use these options.',
    tutorial_10:
        'You can choose to limit the time (in seconds) for every game by selecting this option and changing the number of seconds. For example: you will have 10 seconds for memoizing the series, and then you will have 10 seconds for entering the correct series.',
    tutorial_11:
        'Good job! You got it right! Though it will not always be so easy Now press the "Next" button to see just few more things...',
    tutorial_12:
        'Here you can check your score: how many games played, how many correct answers and the success rate.',
    tutorial_13:
        'Congratulations! Please press the quit tutorial button and enjoy the game!',
    tutorial_next: 'Next',
    tutorial_go_back: 'Go Back',

    notification_default: 'Press to start the game',
    notification_stage_01: 'Close memorized number',
    notification_stage_02: 'Press open and type in',
    notification_stage_03: 'Type memorized number',
    notification_stage_04: 'Play Next Game',
    number_from_computer_user_instructions: 'Memorize sequence',
    user_input_user_instructions: 'Type sequence',
    title: 'Memorize a sequence of numbers',
    description:
        'Practice your memory by memorizing different sequences of numbers with incrementing difficulty levels',
    game_statistics: 'Game Statistics',
    stop: 'Stop',
    pause: 'Pause',
    paused: 'Paused',
    how_to_play: 'How to play',
    how_to_play_details:
        'In this game your goal is to memorize a series of random numbers. The computer will create a random series of numbers. Try to memoize this series of numbers. Once you finished memoizing the number, press the Close button and the number will disappear. Then press Open button and enter the numbers in same order. If you can not remember the number, you can press the Skip button.',
    how_to_play_close: 'Close',
};

const ru: Required<I18nScope> = {
    press_start_to_begin: 'Нажмите, чтобы начать играть!',
    play_now: 'Начинать!',

    playing: 'Играем!',

    score_games_played: 'сыграно ',
    score_correct_answers: 'правильно ',
    score_success_rate: 'Уровень',
    score_strength_of_sequence: 'Сила последовательности',
    close_number: 'Закрыть номер',
    enter_number: 'Введите номер',
    skip: 'Пропустить',
    next: 'Следующий',
    settings_title: 'Настройки игры',
    settings_auto_increment:
        'Автоматически увеличивать длину последовательности',
    settings_auto_move_to_next_game:
        'Автоматически переходить к следующей игре',
    settings_length_of_sequence: 'Длина последовательности',
    settings_apply_time_limit: 'Применить ограничение по времени (сек)',
    settings_save: 'Сохранить настройки',
    settings_close: 'Закрыть настройки',

    settings_saved: 'Новые настройки сохранены!',
    settings_enter_positive_number: 'Пожалуйста, введите положительное число',

    tutorial_01:
        'В этой игре ваша цель - запомнить серию случайных чисел. Нажмите кнопку Пуск, чтобы начать ...',
    tutorial_02:
        'Компьютер создаст случайную серию чисел. Попробуйте запомнить эту серию цифр.',
    tutorial_03:
        'Как только вы закончите запоминать номер, нажмите кнопку Close, и номер исчезнет ...',
    tutorial_04:
        'После того, как вы запомнили числа, нажмите здесь и введите эти числа ...',
    tutorial_05:
        'Введите здесь серию чисел, которые вы запомнили. (Подсказка: 1,2,3,4,5)',
    tutorial_06:
        'Если вы не можете вспомнить номер, вы можете нажать кнопку пропуска и попытать счастья с новым номером.',
    tutorial_07:
        'Вы можете изменить способ ведения этой игры, нажмите кнопку Настройки , чтобы узнать, как:',
    tutorial_08:
        'Если вы не хотите автоматически переходить к следующей серии чисел после каждой игры, выберите эту опцию, и вы сможете перемещаться вручную после нажатия кнопки',
    tutorial_09:
        'Когда вы начинаете играть, длина последовательности начинается с 4 чисел и постепенно увеличивается. Если вы хотите начать с другой длины и / или не хотите, чтобы длина увеличивалась, используйте эти параметры.',
    tutorial_10:
        'Вы можете ограничить время (в секундах) для каждой игры, выбрав эту опцию и изменив количество секунд. Например: у вас будет 10 секунд для запоминания серии, а затем у вас будет 10 секунд для ввода правильной серии.',
    tutorial_11:
        'Молодец! Вы все правильно поняли! Хотя это не всегда будет так просто. Теперь нажмите кнопку Далее, чтобы увидеть еще кое-что ...',
    tutorial_12:
        'Здесь вы можете проверить свой счет: сколько игр сыграно, сколько правильных ответов и процент успеха',
    tutorial_13:
        'Поздравляю! Пожалуйста, нажмите кнопку выхода из обучения и наслаждайтесь игрой!',
    tutorial_next: 'Далее',
    tutorial_go_back: 'Вернуться',

    notification_default: 'Нажмите чтобы начать игру',
    notification_stage_01: 'Закрыть запомненное число',
    notification_stage_02: 'Нажмите открыть и введите',
    notification_stage_03: 'Введите запомненное число',
    notification_stage_04: 'Следующая игра',
    number_from_computer_user_instructions: 'Запомните последовательность',
    user_input_user_instructions: 'Введите последовательность',
    title: 'Запомните последовательность чисел',
    description:
        'Практикуйте свою память, запоминая различные последовательности чисел с возрастающими уровнями сложности',
    game_statistics: 'Статистика игры',
    stop: 'Останавливаться',
    pause: 'Пауза',
    paused: 'Пауза',
    how_to_play: 'Как играть',
    how_to_play_details:
        'В этой игре ваша цель состоит в том, чтобы запомнить ряд случайных чисел. Компьютер создаст случайный ряд чисел. Попробуйте запомнить эту серию чисел. Когда вы закончите запоминать номер, нажмите кнопку «Закрыть», и номер исчезнет. Затем нажмите кнопку «Открыть» и введите числа в том же порядке. Если вы не можете вспомнить номер, вы можете нажать кнопку «Пропустить».',
    how_to_play_close: 'Закрывать',
};

const es: Required<I18nScope> = {
    press_start_to_begin: '¡Presione para comenzar a jugar!',
    play_now: '¡Comienzo!',

    playing: '¡Jugando!',

    score_games_played: 'JUGÓ',
    score_correct_answers: 'Correcto',
    score_success_rate: 'calificar',
    score_strength_of_sequence: 'Fuerza de secuencia',
    close_number: 'Cerrar número',
    enter_number: 'Ingresar número',
    skip: 'Saltar',
    next: 'siguiente',
    settings_title: 'Configuración del juego',
    settings_auto_increment:
        'Incrementar automáticamente la longitud de la secuencia',
    settings_auto_move_to_next_game: 'Pasar automáticamente al siguiente juego',
    settings_length_of_sequence: 'Longitud de la secuencia',
    settings_apply_time_limit: 'Aplicar límite de tiempo (segundos)',
    settings_save: 'Guardar configuración',
    settings_close: 'Cerrar configuración',

    settings_saved: '¡Nueva configuración guardada!',
    settings_enter_positive_number: 'Ingrese un número positivo',

    tutorial_01:
        'En este juego, tu objetivo es memorizar una serie de números aleatorios. Presione el botón Inicio para comenzar ...',
    tutorial_02:
        'La computadora creará una serie aleatoria de números. Intente memorizar esta serie de números.',
    tutorial_03:
        'Una vez que haya terminado de memorizar el número, presione el botón Cerrar y el número desaparecerá ...',
    tutorial_04:
        'Después de memorizar los números, presione aquí y escriba esos números ...',
    tutorial_05:
        'Escriba aquí la serie de números que memorizó. (Pista: 1,2,3,4,5)',
    tutorial_06:
        'Si no puede recordar el número, puede presionar el botón de saltar y probar suerte con un nuevo número.',
    tutorial_07:
        'Puedes cambiar la forma en que se juega este juego, presiona el botón Configuración para ver cómo:',
    tutorial_08:
        'Si no desea pasar automáticamente a la siguiente serie de números después de cada juego, seleccione esta opción y podrá moverse manualmente después de presionar el botón Siguiente.',
    tutorial_09:
        'Cuando comienzas a jugar, la duración de la secuencia comienza a partir de 4 números y aumenta gradualmente. Si desea comenzar con una longitud diferente y / o no desea que la longitud aumente, use estas opciones.',
    tutorial_10:
        'Puede elegir limitar el tiempo (en segundos) para cada juego seleccionando esta opción y cambiando el número de segundos. Por ejemplo: tendrá 10 segundos para memorizar la serie, y luego tendrá 10 segundos para ingresar la serie correcta.',
    tutorial_11:
        '¡Buen trabajo! ¡Lo hiciste bien! Aunque no siempre será tan fácil Ahora presione el botón "Siguiente" para ver algunas cosas más ...',
    tutorial_12:
        'Aquí puede verificar su puntaje: cuántos juegos jugados, cuántas respuestas correctas y la tasa de éxito.',
    tutorial_13:
        '¡Felicidades! ¡Pulsa el botón para salir del tutorial y disfruta del juego!',
    tutorial_next: 'Siguiente',
    tutorial_go_back: 'Regresar',

    notification_default: 'Pulse para comenzar el juego',
    notification_stage_01: 'Cerrar número memorizado',
    notification_stage_02: 'Pulse abrir y escribir',
    notification_stage_03: 'Escribir número memorizado',
    notification_stage_04: 'Jugar al siguiente juego',
    number_from_computer_user_instructions: 'Memorice secuencia',
    user_input_user_instructions: 'Escriba secuencia',
    title: 'Memoriza una secuencia de números',
    description:
        'Practica tu memoria memorizando diferentes secuencias de números con niveles de dificultad crecientes',
    game_statistics: 'Estadísticas del juego',
    stop: 'Detener',
    pause: 'Pausa',
    paused: 'Pausa',
    how_to_play: 'Cómo jugar',
    how_to_play_details:
        'En este juego tu objetivo es memorizar una serie de números aleatorios. La computadora creará una serie aleatoria de números. Trate de memorizar esta serie de números. Una vez que termine de memorizar el número, presione el botón Cerrar y el número desaparecerá. Luego presione el botón Abrir e ingrese los números en el mismo orden. Si no puede recordar el número, puede presionar el botón Saltar.',
    how_to_play_close: 'Cerca',
};

const pt: Required<I18nScope> = {
    press_start_to_begin: 'Pressione para começar a jogar!',
    play_now: 'Começar!',

    playing: 'Jogando!',

    score_games_played: 'Jogos',
    score_correct_answers: 'corretas',
    score_success_rate: 'sucesso',
    score_strength_of_sequence: 'Sequence Strength',
    close_number: 'Fechar Número',
    enter_number: 'Digite o número',
    skip: 'Pular',
    next: 'Próximo',
    settings_title: 'Configurações do jogo',
    settings_auto_increment:
        'Aumentar automaticamente o comprimento da sequência',
    settings_auto_move_to_next_game:
        'Mover automaticamente para o próximo jogo',
    settings_length_of_sequence: 'Comprimento da sequência',
    settings_apply_time_limit: 'Aplicar limite de tempo (seg)',
    settings_save: 'Salvar configurações',
    settings_close: 'Fechar configurações',

    settings_saved: 'Novas configurações salvas!',
    settings_enter_positive_number: 'Por favor, insira um número positivo',

    tutorial_01:
        'Neste jogo, seu objetivo é memorizar uma série de números aleatórios. Pressione o botão Iniciar para começar ...',
    tutorial_02:
        'O computador criará uma série aleatória de números. Tente memorizar esta série de números.',
    tutorial_03:
        'Assim que terminar de memorizar o número, pressione o botão Fechar e o número desaparecerá ...',
    tutorial_04:
        'Depois de memorizar os números, pressione aqui e digite esses números ...',
    tutorial_05:
        'Digite aqui a série de números que você memorizou. (Dica: 1,2,3,4,5)',
    tutorial_06:
        'Se você não se lembra do número, pode pressionar o botão pular e tentar a sorte com um novo número.',
    tutorial_07:
        'Você pode mudar a forma como este jogo é jogado, pressione o botão Configurações para ver como:',
    tutorial_08:
        'Se você não deseja mover automaticamente para a próxima série de números após cada jogo, selecione esta opção e você poderá mover manualmente após pressionar o botão Próximo.',
    tutorial_09:
        'Quando você começa a tocar, a duração da sequência começa com 4 números e aumenta gradualmente. Se você quiser começar com comprimentos diferentes e / ou não quiser que o comprimento aumente, use estas opções.',
    tutorial_10:
        'Você pode escolher limitar o tempo (em segundos) para cada jogo, selecionando esta opção e alterando o número de segundos. Por exemplo: você terá 10 segundos para memorizar a série e, em seguida, 10 segundos para inserir a série correta.',
    tutorial_11:
        'Bom trabalho! Você acertou! Embora nem sempre seja tão fácil Agora pressione o botão "Próximo" para ver apenas mais algumas coisas ...',
    tutorial_12:
        'Aqui você pode verificar sua pontuação: quantos jogos jogou, quantas respostas corretas e a taxa de sucesso.',
    tutorial_13:
        'Parabéns! Por favor, pressione o botão para sair do tutorial e aproveite o jogo!',
    tutorial_next: 'Next',
    tutorial_go_back: 'Voltar',

    notification_default: 'Aperte para começar o jogo',
    notification_stage_01: 'Fechar número memorizado',
    notification_stage_02: 'Aperte abrir e digitar',
    notification_stage_03: 'Digitar número memorizado',
    notification_stage_04: 'Jogar o próximo jogo',
    number_from_computer_user_instructions: 'Memorize sequência',
    user_input_user_instructions: 'Digite sequência',
    title: 'Memorize uma sequência de números',
    description:
        'Pratique sua memória memorizando diferentes sequências de números com níveis de dificuldade crescentes',
    game_statistics: 'Estatísticas do jogo',
    stop: 'Pare',
    pause: 'Pausa',
    paused: 'Pausado',
    how_to_play: 'Como jogar',
    how_to_play_details:
        'Neste jogo seu objetivo é memorizar uma série de números aleatórios. O computador criará uma série aleatória de números. Tente memorizar esta série de números. Quando terminar de memorizar o número, pressione o botão Fechar e o número desaparecerá. Em seguida, pressione o botão Abrir e digite os números na mesma ordem. Se você não consegue lembrar o número, você pode pressionar o botão Ignorar.',
    how_to_play_close: 'Fechar',
};

const fr: Required<I18nScope> = {
    press_start_to_begin: 'Appuyez pour commencer à jouer!',
    play_now: 'Démarrer!',

    playing: 'Jouant!',

    score_games_played: 'joués',
    score_correct_answers: 'correctes',
    score_success_rate: 'réussite',
    score_strength_of_sequence: 'Force de la séquence',
    close_number: 'Fermer le numéro',
    enter_number: 'Entrez le numéro',
    skip: 'Ignorer',
    next: 'Next',
    settings_title: 'Paramètres du jeu',
    settings_auto_increment:
        'Incrémenter automatiquement la longueur de la séquence',
    settings_auto_move_to_next_game: 'Passer automatiquement au jeu suivant',
    settings_length_of_sequence: 'Longueur de la séquence',
    settings_apply_time_limit: 'Appliquer la limite de temps (sec)',
    settings_save: 'Enregistrer les paramètres',
    settings_close: 'Fermer les paramètres',

    settings_saved: 'Nouveaux paramètres enregistrés!',
    settings_enter_positive_number: 'Veuillez saisir un nombre positif',

    tutorial_01:
        ' Dans ce jeu, votre objectif est de mémoriser une série de nombres aléatoires. Appuyez sur le bouton Démarrer pour commencer...',
    tutorial_02:
        'Lordinateur créera une série aléatoire de nombres. Essayez de mémoriser cette série de chiffres.',
    tutorial_03:
        'Une fois que vous avez fini de mémoriser le numéro, appuyez sur le bouton Fermer et le numéro disparaîtra...',
    tutorial_04:
        'Après avoir mémorisé les chiffres, appuyez ici et tapez ces chiffres...',
    tutorial_05:
        'Tapez ici la série de nombres que vous avez mémorisée. (Indice : 1,2,3,4,5)',
    tutorial_06:
        'Si vous ne vous souvenez plus du numéro, vous pouvez appuyer sur le bouton Passer et tenter votre chance avec un nouveau numéro.',
    tutorial_07:
        'Vous pouvez changer la façon dont ce jeu est joué, appuyez sur le bouton Paramètres pour voir comment :',
    tutorial_08:
        'Si vous ne souhaitez pas passer automatiquement à la prochaine série de numéros après chaque jeu, sélectionnez cette option et vous pourrez vous déplacer manuellement après avoir appuyé sur le bouton Suivant.',
    tutorial_09:
        'Lorsque vous commencez à jouer, la longueur de la séquence commence à partir de 4 chiffres et augmente progressivement. Si vous voulez commencer avec une longueur différente et/ou ne voulez pas que la longueur augmente, utilisez ces options.',
    tutorial_10:
        'Vous pouvez choisir de limiter le temps (en secondes) pour chaque jeu en sélectionnant cette option et en modifiant le nombre de secondes. Par exemple : vous aurez 10 secondes pour mémoriser la série, puis vous aurez 10 secondes pour saisir la bonne série.',
    tutorial_11:
        'Bon travail! Vous avez bien compris! Bien que ce ne soit pas toujours aussi facile, appuyez maintenant sur le bouton "Suivant" pour voir quelques autres choses...',
    tutorial_12:
        'Ici, vous pouvez vérifier votre score : combien de parties jouées, combien de bonnes réponses et le taux de réussite.',
    tutorial_13:
        'Toutes nos félicitations! Veuillez appuyer sur le bouton Quitter le didacticiel et profitez du jeu !',
    tutorial_next: 'Suivant',
    tutorial_go_back: 'Revenir en arrière',

    notification_default: 'Appuyez pour démarrer le jeu',
    notification_stage_01: 'Fermer le nombre mémorisé',
    notification_stage_02: 'Appuyer sur ouvrir et taper',
    notification_stage_03: 'Taper le nombre mémorisé',
    notification_stage_04: 'Jouer au prochain jeu',
    number_from_computer_user_instructions: 'Mémorisez séquence',
    user_input_user_instructions: 'Tapez séquence',
    title: 'Mémoriser une séquence',
    description:
        'Entraînez votre mémoire en mémorisant différentes séquences de nombres avec des niveaux de difficulté croissants',
    game_statistics: 'Statistiques du jeu',
    stop: 'Arrêter',
    pause: 'Pause',
    paused: 'Pause',
    how_to_play: 'Comment jouer',
    how_to_play_details:
        'Dans ce jeu, votre objectif est de mémoriser une série de nombres aléatoires. L`ordinateur créera une série aléatoire de nombres. Essayez de mémoriser cette série de chiffres. Une fois que vous avez fini de mémoriser le numéro, appuyez sur le bouton Fermer et le numéro disparaîtra. Appuyez ensuite sur le bouton Ouvrir et saisissez les chiffres dans le même ordre. Si vous ne vous souvenez pas du numéro, vous pouvez appuyer sur le bouton Skip.',
    how_to_play_close: 'Fermer',
};

const translate: any = {
    en: en,
    fr: fr,
    es: es,
    pt: pt,
    ru: ru,
};

export { translate };
