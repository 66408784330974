import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import * as selectors from '../../redux/selectors';

import flag_en from '../../../icons/flag_en.jpg';
import flag_es from '../../../icons/flag_es.jpg';
import flag_fr from '../../../icons/flag_fr.jpg';
import flag_ru from '../../../icons/flag_ru.jpg';
import flag_pt from '../../../icons/flag_pt.jpg';

const localeConfig_: any = [
    { name: 'en', flag: flag_en, flag_emoji: '&#1F1FA', alias: '' },
    { name: 'es', flag: flag_es, flag_emoji: '&#1F1EA', alias: 'es' },
    { name: 'fr', flag: flag_fr, flag_emoji: '&#1F1EB', alias: 'fr' },
    { name: 'ru', flag: flag_ru, flag_emoji: '&#1F1F7', alias: 'ru' },
    { name: 'pt', flag: flag_pt, flag_emoji: '&#1F1F5', alias: 'pt' },
];

const Locale = () => {
    const [path, setPath] = useState('/');
    const [toggleWidget, setToggleWidget] = useState(false);
    const location = useLocation();

    const currentLocale = useSelector((state: RootState) =>
        selectors.selectLocale(state)
    );
    const widget = localeConfig_.map((locale: any, index: any) => (
        <a
            key={`locale_${index}`}
            href={locale.alias ? `/${locale.alias}${path}` : `${path}`}
            className={`${
                currentLocale === locale.name
                    ? 'locale-link-current'
                    : 'locale-link'
            } ${toggleWidget ? 'show-locale-widget' : 'hide-locale-widget'}`}
            onClick={(e) => {
                if (currentLocale === locale.name) {
                    e.preventDefault();
                    setToggleWidget(!toggleWidget);
                }
            }}
        >
            <img src={locale.flag} className="locale-flag" alt={locale.alias} />
        </a>
    ));

    useEffect(() => {
        const _path =
            currentLocale === 'en'
                ? location.pathname
                : `/${location.pathname.split('/').slice(2).join('/')}`;

        setPath(_path);
    }, [currentLocale, location.pathname]);

    return (
        <div className="locale-widget">
            <div className="locale-widget-int">{widget}</div>
        </div>
    );
};

export default Locale;
