import ButtonStartGameWithNotifications from '../ButtonStartGameWithNotifications';
import ButtonPauseGame from '../ButtonPauseGame';
import ButtonStopGame from '../ButtonStopGame';

const GameControls = () => {
    return (
        <div className="layout-component-wrapper game-notifications-component-wrapper">
            <ButtonStartGameWithNotifications />
            <ButtonPauseGame />
            <ButtonStopGame />
        </div>
    );
};

export default GameControls;
