interface FaqScope {
    q_01: string;
    q_02: string;
    q_03: string;
    q_04: string;
    q_05: string;
    q_06: string;
    q_07: string;
    a_01: string;
    a_02: string;
    a_03: string;
    a_04: string;
    a_05: string;
    a_06: string;
    a_07: string;
}

const en: Required<FaqScope> = {
    q_01: 'What is this?',
    q_02: 'Why should I play these games?',
    q_03: 'When should I play these games?',
    q_04: 'Who should play these games?',
    q_05: 'How much time should I spend playing?',
    q_06: 'How much does it cost?',
    q_07: 'Are you collecting my personal data?',
    a_01: 'This website is about educational games which are good for stimulating and training the short-term and long-term memory of the player who plays these games.',
    a_02: 'Training your memory is a good way to stimulate your brain activity, which according to research contribute to many aspects related to mental and physical health.',
    a_03: 'As the name "Brain Squatting" suggests, consider it as a workout for your brain and do it whenever you feel like.',
    a_04: 'Since this is an educational game is is suitable for all ages, whether you are a student and want to improve your memory for studying purposes or an adult and just want to strenghten your memory and cognitive abilities.',
    a_05: 'Most common is to play for few minutes on a regular basis.',
    a_06: 'The basic games are free and will always remain free. This website revenue model is based on ads.',
    a_07: 'No personal data is collected. Anonymous data which collected is for anonymous website traffic analysis.',
};

const ru: Required<FaqScope> = {
    q_01: 'Что это?',
    q_02: 'Почему я должен играть в эти игры?',
    q_03: 'Когда я должен играть в эти игры?',
    q_04: 'Кому стоит играть в эти игры?',
    q_05: 'Сколько времени я должен проводить за игрой?',
    q_06: 'Сколько это стоит?',
    q_07: 'Собираете ли вы какие-либо личные данные?',
    a_01: 'Этот сайт посвящен развивающим играм, которые хороши для стимуляции и тренировки кратковременной и долговременной памяти игрока, играющего в эти игры.',
    a_02: 'Тренировка памяти — это хороший способ стимулировать активность мозга, которая, согласно исследованиям, влияет на многие аспекты, связанные с психическим и физическим здоровьем.',
    a_03: 'Как следует из названия «Мозговые приседания», рассматривайте это как тренировку для вашего мозга и делайте это, когда вам захочется.',
    a_04: 'Поскольку это образовательная игра, она подходит для всех возрастов, независимо от того, являетесь ли вы студентом и хотите улучшить свою память для учебных целей или взрослым и просто хотите укрепить свою память и когнитивные способности.',
    a_05: 'Наиболее распространенная рутина – регулярно играть по несколько минут.',
    a_06: 'Основные игры бесплатны и всегда останутся бесплатными. Модель дохода сайта основана на рекламе.',
    a_07: 'Персональные данные не собираются. Собранные анонимные данные предназначены для анонимного анализа посещаемости веб-сайта.',
};

const pt: Required<FaqScope> = {
    q_01: 'O que é isso?',
    q_02: 'Por que devo jogar esses jogos?',
    q_03: 'Quando devo jogar esses jogos?',
    q_04: 'Quem deve jogar esses jogos?',
    q_05: 'Quanto tempo devo gastar jogando?',
    q_06: 'Quanto custa?',
    q_07: 'Você está coletando algum dado pessoal?',
    a_01: 'Este site é sobre jogos educativos que são bons para estimular e treinar a memória de curto e longo prazo do jogador que joga esses jogos.',
    a_02: 'Treinar sua memória é uma boa maneira de estimular sua atividade cerebral, que segundo pesquisas contribuem para muitos aspectos relacionados à saúde mental e física.',
    a_03: 'Como o nome "Brain Squatting" sugere, considere-o como um treino para o seu cérebro e faça-o sempre que sentir vontade.',
    a_04: 'Como este é um jogo educativo, é adequado para todas as idades, seja você um estudante e deseja melhorar sua memória para fins de estudo ou um adulto e apenas deseja fortalecer sua memória e habilidades cognitivas.',
    a_05: 'O mais comum é jogar por alguns minutos regularmente.',
    a_06: 'Os jogos básicos são gratuitos e sempre serão gratuitos. Este modelo de receita do site é baseado em anúncios.',
    a_07: 'Nenhum dado pessoal é coletado. Os dados anônimos coletados são para análise de tráfego anônimo do site.',
};

const fr: Required<FaqScope> = {
    q_01: 'Qu`est-ce que c`est?',
    q_02: 'Pourquoi devrais-je jouer à ces jeux ?',
    q_03: 'Quand dois-je jouer à ces jeux ?',
    q_04: 'Qui devrait jouer à ces jeux ?',
    q_05: 'Combien de temps dois-je passer à jouer ?',
    q_06: 'Combien ça coûte?',
    q_07: 'Collectez-vous des données personnelles?',
    a_01: 'Ce site Web concerne les jeux éducatifs qui sont bons pour stimuler et entraîner la mémoire à court et à long terme du joueur qui joue à ces jeux.',
    a_02: 'Entraîner votre mémoire est un bon moyen de stimuler votre activité cérébrale, ce qui, selon les recherches, contribue à de nombreux aspects liés à la santé mentale et physique.',
    a_03: 'Comme le suggère le nom « Brain Squatting », considérez-le comme un entraînement pour votre cerveau et faites-le quand vous en avez envie.',
    a_04: 'Étant donné qu`il s`agit d`un jeu éducatif, il convient à tous les âges, que vous soyez étudiant et que vous souhaitiez améliorer votre mémoire à des fins d`étude ou que vous soyez un adulte et que vous souhaitiez simplement renforcer votre mémoire et vos capacités cognitives.',
    a_05: 'Le plus courant est de jouer régulièrement quelques minutes.',
    a_06: 'Les jeux de base sont gratuits et le resteront toujours. Ce modèle de revenus de site Web est basé sur des publicités.',
    a_07: 'Aucune donnée personnelle n`est collectée. Les données anonymes collectées sont destinées à l`analyse anonyme du trafic du site Web.',
};

const es: Required<FaqScope> = {
    q_01: '¿Qué es esto?',
    q_02: '¿Por qué debo jugar estos juegos?',
    q_03: '¿Cuándo debo jugar estos juegos?',
    q_04: '¿Quién debería jugar estos juegos?',
    q_05: '¿Cuánto tiempo debo pasar jugando?',
    q_06: '¿Cuánto cuesta?',
    q_07: '¿Está recopilando datos personales?',
    a_01: 'Este sitio web trata sobre juegos educativos que son buenos para estimular y entrenar la memoria a corto y largo plazo del jugador que juega estos juegos.',
    a_02: 'Entrenar tu memoria es una buena manera de estimular tu actividad cerebral, que según las investigaciones contribuyen en muchos aspectos relacionados con la salud mental y física.',
    a_03: 'Como sugiere el nombre "Brain Squatting", considérelo como un ejercicio para su cerebro y hágalo cuando lo desee.',
    a_04: 'Dado que este es un juego educativo, es adecuado para todas las edades, tanto si eres un estudiante y quieres mejorar tu memoria para estudiar como si eres un adulto y solo quieres fortalecer tu memoria y tus habilidades cognitivas.',
    a_05: 'Lo más común es jugar durante unos minutos de forma regular.',
    a_06: 'Los juegos básicos son gratuitos y siempre lo serán. El modelo de ingresos de este sitio web se basa en anuncios.',
    a_07: 'No se recopilan datos personales. Los datos anónimos que se recopilan son para el análisis anónimo del tráfico del sitio web.',
};

const translate: any = {
    en: en,
    fr: fr,
    es: es,
    pt: pt,
    ru: ru,
};

export { translate };
