import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import * as selectors from '../../redux/selectors';
import { ACTION_APP_SWITCH_THEME } from '../../redux/constants';
import { translate } from '../../i18n';

import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
const ThemeSwitchNew = () => {
    const dispatch = useDispatch();
    const isDarkTheme = useSelector((state: RootState) =>
        selectors.selectIsDarkTheme(state)
    );
    const locale = useSelector((state: RootState) =>
        selectors.selectLocale(state)
    );

    return (
        <div className="theme-switch-new">
            {!isDarkTheme ? (
                <div className="switch-to-night-mode">
                    <button
                        className="switch-theme-mode-button"
                        title={translate[locale].switch_to_dark_theme}
                        disabled={isDarkTheme}
                        onClick={() =>
                            dispatch({
                                type: ACTION_APP_SWITCH_THEME,
                            })
                        }
                    >
                        <EmojiObjectsIcon className="dark-mode-icon" />
                    </button>
                </div>
            ) : (
                <div className="switch-to-day-mode">
                    <button
                        className="switch-theme-mode-button"
                        title={translate[locale].switch_to_bright_theme}
                        disabled={!isDarkTheme}
                        onClick={() =>
                            dispatch({
                                type: ACTION_APP_SWITCH_THEME,
                            })
                        }
                    >
                        <EmojiObjectsIcon />
                    </button>
                </div>
            )}
        </div>
    );
};

export default ThemeSwitchNew;
