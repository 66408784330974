import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Emoji, CellNProps, EmojiNew } from '../../types';

const CellN = (props: CellNProps) => {
    const [htmlContent, setHtmlContent] = useState<string>('🟩');
    // //console.log('what: ', props.emoji);
    const { emoji, handleClickOnEmojiNew } = props;
    const { picture, dataAtt, isOpen } = emoji;

    useEffect(() => {
        //console.log('useEffect: isOpen', isOpen);
        if (emoji.isOpen) {
            setHtmlContent(picture);
        } else {
            setHtmlContent('🟩');
        }
    }, [emoji.isOpen]);

    useEffect(() => {
        //console.log('useEffect: htmlContent', htmlContent);
        // if (isOpen) {
        //     setHtmlContent(picture);
        // } else {
        //     setHtmlContent('🟩');
        // }
    }, [htmlContent]);
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: isOpen ? picture : '🟩',
            }}
            // dangerouslySetInnerHTML={{
            //     __html: picture,
            // }}
            className="game-grid-cell"
            data-emoji={dataAtt}
            onClick={(e) => {
                handleClickOnEmojiNew(emoji);
            }}
        />
    );
};

export default CellN;
