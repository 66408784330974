import React, {
    useMemo,
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '../../redux/selectors';
import { RootState } from '../../../redux/store';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './banner.styles.css';
import TypingEffect from '../TypingEffect';

{
    /* <TypingEffect text={dsecription} /> */
}

const ContentI18n = [
    [
        {
            title: ['Train', 'your', 'memory'],
            features: [
                'Enhances social skills',
                'Increasing creativity',
                'Reduces stress & anxiety',
                'Improves comm. skills',
                'Increasing well-being',
            ],
            effectiveGames: 'Effective games',
            lang: 'en',
            accordingToResearches:
                'according to researches memory games can contribute to:',
            story01:
                "According to numerous studies, memory games have been shown to contribute to several benefits, including enhanced social skills, increased creativity, reduced stress and anxiety, improved communication skills, and increased well-being. If you're looking to improve your cognitive function and overall well-being, playing memory games is a great way to do it. Not only are they fun and engaging, but they can also help you improve your memory, focus, and concentration. By challenging your brain to remember and recall information, you're strengthening your neural connections and building new ones. Plus, the benefits of memory games extend far beyond just your brain. As mentioned earlier, memory games have been shown to contribute to improved social skills, reduced stress and anxiety, and increased well-being.",
        },
        {
            title: ['entraînez ', 'votre', 'mémoire'],
            features: [
                'Améliore la puissance cérébrale',
                'Améliore la rétention de la mémoire',
                'Améliore la concentration',
                "Augmente l'agilité mentale",
                'Améliore les compétences de résolution de problèmes',
            ],
            lang: 'fr',
            effectiveGames: 'Jeux efficaces',
            accordingToResearches:
                'Selon les recherches, les jeux de mémoire peuvent contribuer à:',
            story01:
                "Selon de nombreuses études, les jeux de mémoire ont montré qu'ils contribuaient à plusieurs avantages, notamment des compétences sociales améliorées, une créativité accrue, une réduction du stress et de l'anxiété, des compétences en communication améliorées et un bien-être accru. Si vous cherchez à améliorer votre fonction cognitive et votre bien-être général, jouer à des jeux de mémoire est un excellent moyen de le faire. Non seulement ils sont amusants et captivants, mais ils peuvent également vous aider à améliorer votre mémoire, votre concentration et votre attention. En défiant votre cerveau à mémoriser et à rappeler des informations, vous renforcez vos connexions neuronales et en créez de nouvelles. De plus, les avantages des jeux de mémoire vont bien au-delà de votre cerveau. Comme mentionné précédemment, les jeux de mémoire ont montré qu'ils contribuaient à une amélioration des compétences sociales, une réduction du stress et de l'anxiété, et un bien-être accru.",
        },
        {
            title: ['Игры', 'Тренировки', 'Памяти'],
            features: [
                'Улучшает социальные навыки',
                'Увеличивает творчество',
                'Снижает стресс и ангидит',
                'Улучшает навыки коммуникации',
                'Увеличивает общее благополучие',
            ],
            lang: 'ru',
            effectiveGames: 'Эффективные игры',
            accordingToResearches:
                'Согласно исследованиям, игры для развития памяти могут быть полезны для:',
            story01:
                'Согласно множеству исследований, игры на память способны дать несколько преимуществ, включая улучшение социальных навыков, повышение креативности, снижение уровня стресса и тревожности, улучшение навыков коммуникации и улучшение благополучия. Если вы хотите улучшить свою когнитивную функцию и общее благополучие, то игры на память - отличный способ это сделать. Они не только забавны и увлекательны, но также могут помочь вам улучшить память, фокус и концентрацию. Вызывая в своем мозге необходимость запоминать и вспоминать информацию, вы укрепляете свои нейронные связи и создаете новые. Плюс, преимущества игр на память выходят далеко за рамки просто мозга. Как упоминалось ранее, игры на память показали свою эффективность в улучшении социальных навыков, снижении уровня стресса и тревожности и улучшении благополучия.',
        },
        {
            title: ['entrena ', 'tu', 'memoria'],
            features: [
                'Mejora la función cognitiva',
                'Mejora la capacidad de multitarea',
                'Aumenta la autoestima',
                'Aumenta la motivación',
                'Aumenta la disciplina',
            ],
            lang: 'es',
            effectiveGames: 'Juegos efectivos',
            accordingToResearches:
                'De acuerdo a investigaciones, los juegos de memoria pueden contribuir a:',
            story01:
                'Según numerosos estudios, los juegos de memoria han demostrado contribuir a varios beneficios, incluyendo habilidades sociales mejoradas, mayor creatividad, reducción del estrés y la ansiedad, habilidades de comunicación mejoradas y mayor bienestar. Si buscas mejorar tu función cognitiva y bienestar en general, jugar juegos de memoria es una excelente manera de hacerlo. No solo son divertidos y atractivos, sino que también pueden ayudarte a mejorar tu memoria, enfoque y concentración. Al desafiar tu cerebro a recordar y recuperar información, estás fortaleciendo tus conexiones neuronales y creando nuevas. Además, los beneficios de los juegos de memoria van mucho más allá de tu cerebro. Como se mencionó anteriormente, los juegos de memoria han demostrado contribuir a una mejora de las habilidades sociales, una reducción del estrés y la ansiedad, y un mayor bienestar.',
        },
        {
            title: ['treine', 'sua', 'memória'],
            features: [
                'Melhora as habilidades sociais',
                'Aumenta a criatividade',
                'Reduz o estresse e a ansiedade',
                'Melhora as habilidades de comunicação',
                'Aumenta o bem-estar geral',
            ],
            lang: 'pt',
            effectiveGames: 'Jogos eficazes',
            accordingToResearches:
                'De acordo com pesquisas, jogos de memória podem contribuir para:',
            story01:
                'De acordo com numerosos estudos, jogos de memória têm sido mostrados a contribuir para vários benefícios, incluindo habilidades sociais aprimoradas, aumento da criatividade, redução do estresse e ansiedade, habilidades de comunicação aprimoradas e maior bem-estar. Se você procura melhorar sua função cognitiva e bem-estar geral, jogar jogos de memória é uma ótima maneira de fazer isso. Não só são divertidos e envolventes, mas também podem ajudá-lo a melhorar sua memória, foco e concentração. Ao desafiar seu cérebro a lembrar e recuperar informações, você está fortalecendo suas conexões neurais e criando novas. Além disso, os benefícios dos jogos de memória se estendem muito além do seu cérebro. Como mencionado anteriormente, os jogos de memória foram mostrados a contribuir para aprimoramento das habilidades sociais, redução do estresse e ansiedade, e maior bem-estar.',
        },
    ],
    [
        {
            lang: 'en',
            title: ['just 3', 'minutes', 'a day'],
            features: [
                'Boosts cognitive function',
                'Improves multitasking',
                'Increasing self-esteem',
                'Increasing motivation',
                'Increasing discipline',
            ],
            effectiveGames: 'Effective games',
            accordingToResearches:
                'according to researches memory games can contribute to:',
        },
        {
            lang: 'fr',
            title: ['seulement', '3 minutes', 'par jour'],
            features: [
                'Améliore la fonction cognitive',
                'Améliore la capacité à faire plusieurs choses à la fois',
                "Augmente l'estime de soi",
                'Augmente la motivation',
                'Augmente la discipline',
            ],
            effectiveGames: 'Jeux efficaces',
            accordingToResearches:
                'Selon les recherches, les jeux de mémoire peuvent contribuer à:',
        },
        {
            lang: 'ru',
            title: ['всего', '3 минуты', 'в день'],
            features: [
                'Усиливает когнитивную функцию',
                'Улучшает умение мультитаскинга',
                'Увеличивает уверенность в себе',
                'Увеличивает мотивацию',
                'Увеличивает дисциплину',
            ],
            effectiveGames: 'Эффективные игры',
            accordingToResearches:
                'Согласно исследованиям, игры для развития памяти могут быть полезны для:',
        },
        {
            lang: 'es',
            title: ['solo', '3 minutos', 'al dia'],
            features: [
                'Mejora las habilidades sociales',
                'Aumenta la creatividad',
                'Reduce el estrés y la ansiedad',
                'Mejora las habilidades de comunicación',
                'Aumenta el bienestar general',
            ],
            effectiveGames: 'Juegos efectivos',
            accordingToResearches:
                'De acuerdo a investigaciones, los juegos de memoria pueden contribuir a:',
        },
        {
            lang: 'pt',
            title: ['apenas', '3 minutos', 'por dia'],
            features: [
                'Melhora o poder cerebral',
                'Melhora a retenção de memória',
                'Melhora a concentração',
                'Aumenta a agilidade mental',
                'Melhora as habilidades de resolução de problemas',
            ],
            effectiveGames: 'Jogos eficazes',
            accordingToResearches:
                'De acordo com pesquisas, jogos de memória podem contribuir para:',
        },
    ],
    [
        {
            lang: 'en',
            title: ['body', 'in', 'mind'],
            features: [
                'Boosts brain power',
                'Enhances memory retention',
                'Improves focus & concentration',
                'Increasing mental agility',
                'Enhances problm-solving',
            ],
            effectiveGames: 'Effective games',
            accordingToResearches:
                'according to researches memory games can contribute to:',
        },
        {
            lang: 'fr',
            title: ['corps', 'a', 'esprit'],
            features: [
                'Améliore les compétences sociales',
                'Augmente la créativité',
                "Réduit le stress et l'anxiété",
                'Améliore les compétences de communication',
                'Augmente le bien-être général',
            ],
            effectiveGames: 'Jeux efficaces',
            accordingToResearches:
                'Selon les recherches, les jeux de mémoire peuvent contribuer à:',
        },
        {
            lang: 'ru',
            description: 'slide_3',
            title: ['Игры', 'Тренировки', 'Памяти'],
            features: [
                'Усиливает мозговую активность',
                'Улучшает удержание памяти',
                'Улучшает сосредоточенность',
                'Увеличивает ментальную гибкость',
                'Улучшает решение проблем',
            ],
            effectiveGames: 'Эффективные игры',
            accordingToResearches:
                'Согласно исследованиям, игры для развития памяти могут быть полезны для',
        },
        {
            lang: 'es',
            description: 'slide_3',

            title: ['cuerpo', 'en', 'mente'],
            features: [
                'Mejora el poder cerebral',
                'Mejora la retención de memoria',
                'Mejora la concentración',
                'Aumenta la agilidad mental',
                'Mejora las habilidades de resolución de problemas',
            ],
            effectiveGames: 'Juegos efectivos',
            accordingToResearches:
                'De acuerdo a investigaciones, los juegos de memoria pueden contribuir a:',
        },
        {
            lang: 'pt',
            description: 'slide_3',
            title: ['corpo', 'em', 'mente'],
            features: [
                'Melhora a função cognitiva',
                'Melhora a capacidade de multitarefa',
                'Aumenta a autoestima',
                'Aumenta a motivação',
                'Aumenta a disciplina',
            ],
            effectiveGames: 'Jogos eficazes',
            accordingToResearches:
                'De acordo com pesquisas, jogos de memória podem contribuir para:',
        },
    ],
];

type BannerContentType = {
    title: Array<string>;
    features: Array<string>;
    currentLocale: string;
};
const Banner2 = () => {
    const currentLocale = useSelector((state: RootState) =>
        selectors.selectLocale(state)
    );

    const [currentContentIndex, setCurrentContentIndex] = useState<number>(0);

    // const [currentContent, setCurrentContent] = useState<BannerContentType>(
    //     ContentI18n[currentContentIndex].fr
    // );

    const currentContent: any = useMemo(
        () =>
            (ContentI18n[0] as unknown as any).find(
                (content: any) => content.lang === currentLocale
            ),
        [currentLocale]
    );
    const _content = [currentContent.story01].join(' ');
    // console.log('_content: ', _content);

    // useEffect(() => {
    //     const intervalSerial = setInterval(() => {
    //         if (currentContentIndex > 1) {
    //             setCurrentContentIndex(0);
    //         } else {
    //             setCurrentContentIndex(currentContentIndex + 1);
    //         }
    //     }, 500); //3700
    //     return () => {
    //         clearInterval(intervalSerial);
    //     };
    // });

    return (
        <div className="top-banner-2">
            <div className="top-banner-left">
                <div className="top-banner-cta">
                    <div>{currentContent.title[0]}</div>
                    <div>{currentContent.title[1]}</div>
                    <div>{currentContent.title[2]}</div>
                </div>
                <div className="top-banner-feature">
                    {currentContent.effectiveGames}
                </div>

                <div className="top-banner-website-url" />
            </div>
            <div className="top-banner-right">
                <div className="top-banner-right-title">
                    <TypingEffect text={_content} />
                </div>
            </div>
        </div>
    );
};

export default Banner2;

/**
 * <div className="top-banner-features">
                    <ul className="features-list">
                        <li className="features-list-item">
                            <CheckBoxIcon />

                            <div className="feature">
                                {currentContent.features[0]}
                            </div>
                        </li>
                        <li className="features-list-item">
                            <CheckBoxIcon />

                            <div className="feature">
                                {currentContent.features[1]}
                            </div>
                        </li>
                        <li className="features-list-item">
                            <CheckBoxIcon />

                            <div className="feature">
                                {currentContent.features[2]}
                            </div>
                        </li>
                        <li className="features-list-item">
                            <CheckBoxIcon />

                            <div className="feature">
                                {currentContent.features[3]}
                            </div>
                        </li>
                        {/* <li className="features-list-item">
                            <CheckBoxIcon />

                            <div className="feature">
                                {currentContent.features[4]}
                            </div>
                        </li> 
                        </ul>
                        </div>
 */
