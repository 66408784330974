// import { createSelector } from 'reselect';
import { RootState } from '../../../redux/store';

const selectNumberFromComputer = (state: RootState) =>
    state.reducerM2.numberFromComputer;

const selectUserInput = (state: RootState) => state.reducerM2.userInput;

const selectIsCorrect = (state: RootState) => state.reducerM2.isCorrect;
const selectIsPlaying = (state: RootState) => state.reducerM2.isPlaying;

const selectIsUserInputOpen = (state: RootState) =>
    state.reducerM2.isUserInputOpen;
const selectIsNumberFromComputerOpen = (state: RootState) =>
    state.reducerM2.isNumberFromComputerOpen;
const selectTutorialMode = (state: RootState) => state.reducerM2.isTutorial;
const selectTutorialStep = (state: RootState) => state.reducerM2.tutorialStep;
const selectCurrentLevel = (state: RootState) => state.reducerM2.currentLevel;
const selectIsSkipped = (state: RootState) => state.reducerM2.isSkipped;
const selectGamesPlayed = (state: RootState) => state.reducerM2.gamesPlayed;
const selectGameStage = (state: RootState) => state.reducerM2.gameStage;

const selectAutoMoveToNextGame = (state: RootState) =>
    state.reducerM2.autoMoveToNextGame;
const selectAutoIncrementalLevels = (state: RootState) =>
    state.reducerM2.autoIncrementalLevels;
const selectApplyGameTimeLimit = (state: RootState) =>
    state.reducerM2.applyGameTimeLimit;
const selectGameTimeLimit = (state: RootState) => state.reducerM2.gameTimeLimit;
const selectTimeLimitPhase01_numberFromComputer = (state: RootState) =>
    state.reducerM2.timeLimitPhase01_numberFromComputer;
const selectGameHistory = (state: RootState) => state.reducerM2.gameHistory;
const selectIsGame = (state: RootState) => state.reducerM2.isGame;
const selectIsPaused = (state: RootState) => state.reducerM2.isPaused;
const selectIsSettingsOpen = (state: RootState) =>
    state.reducerM2.isSettingsOpen;
const selectGameProgress = (state: RootState) => state.reducerM2.gameProgress;
const selectPoints = (state: RootState) => state.reducerM2.points;
const selectPointsWithinLevel = (state: RootState) =>
    state.reducerM2.pointsWithinLevel;
const selectMaxPointsWithinLevel = (state: RootState) =>
    state.reducerM2.maxPointsWithinLevel;
const selectSecondsElapsed = (state: RootState) =>
    state.reducerM2.secondsElapsed;

export {
    selectNumberFromComputer,
    selectUserInput,
    selectIsCorrect,
    selectIsPlaying,
    selectIsUserInputOpen,
    selectIsNumberFromComputerOpen,
    selectTutorialMode,
    selectTutorialStep,
    selectCurrentLevel,
    selectIsSkipped,
    selectGamesPlayed,
    selectGameStage,
    selectAutoMoveToNextGame,
    selectAutoIncrementalLevels,
    selectApplyGameTimeLimit,
    selectGameTimeLimit,
    selectTimeLimitPhase01_numberFromComputer,
    selectGameHistory,
    selectIsGame,
    selectIsPaused,
    selectIsSettingsOpen,
    selectGameProgress,
    selectPoints,
    selectPointsWithinLevel,
    selectMaxPointsWithinLevel,
    selectSecondsElapsed,
};
