import { useSelector } from 'react-redux';
import * as selectors from '../../redux/selectors';
import { RootState } from '../../../redux/store';
import { meta } from '../../../config';

const HeaderWebsiteTitle = () => {
    const locale = useSelector((state: RootState) =>
        selectors.selectLocale(state)
    );
    return (
        <div className="header-website-title" data-cy="header-website-title">
            <a
                className="header-website-title-anchor"
                href={`/${locale === 'en' ? '' : locale}`}
            >
                <span>{meta.title}</span>
            </a>
        </div>
    );
};

export default HeaderWebsiteTitle;
