import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionUserTotalInputCorrect } from '../../redux/actions';
import { RootState } from '../../../redux/store';
import * as constants from '../../redux/constants';
import NumberUserInputBox from '../NumberUserInputBox';
import { translate } from '../../i18n';
import Typography from '@material-ui/core/Typography';

const NumberUserInput = () => {
    const dispatch = useDispatch();
    const inputBoxRef: any = useRef(null);
    let arrayOfInputBoxes = [];

    const currentLevel = useSelector(
        (state: RootState) => state.reducerM2.currentLevel
    );
    const isCorrect = useSelector(
        (state: RootState) => state.reducerM2.isCorrect
    );
    const isUserInputOpen = useSelector(
        (state: RootState) => state.reducerM2.isUserInputOpen
    );
    const numberFromComputer = useSelector(
        (state: RootState) => state.reducerM2.numberFromComputer
    );
    const gameProgress = useSelector(
        (state: RootState) => state.reducerM2.gameProgress
    );
    const isPlaying = useSelector(
        (state: RootState) => state.reducerM2.isPlaying
    );
    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );
    const locale = useSelector((state: RootState) => state.reducerApp.locale);

    const [currentUserInputIndex, setCurrentUserInputIndex] = useState(0);

    const isDisabled = useMemo(
        () =>
            gameStage !== constants.STAGE_03_USER_INPUT_OPEN &&
            gameStage !== constants.STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
        [gameStage]
    );

    const userInputStepForward = () => {
        setCurrentUserInputIndex(
            Math.min(currentUserInputIndex + 1, currentLevel - 1)
        );
    };
    const userInputStepBackward = () => {
        setCurrentUserInputIndex(Math.max(currentUserInputIndex - 1, 0));
    };

    const userManuallyFocusedOnBox = (boxIndex: number) => {
        setCurrentUserInputIndex(boxIndex);
    };

    useEffect(() => {
        if (isCorrect) inputBoxRef.current.blur();
    }, [isCorrect]);

    useEffect(() => {
        setCurrentUserInputIndex(0);
    }, [numberFromComputer]);

    useEffect(() => {
        if (
            isPlaying &&
            !gameProgress.some((element: boolean) => element === false)
        )
            dispatch(actionUserTotalInputCorrect());
    }, [gameProgress, dispatch, isPlaying]);

    useEffect(() => {
        if (isUserInputOpen) {
            inputBoxRef.current.focus({ preventScroll: true });
        }
    }, [isUserInputOpen, currentUserInputIndex]);

    for (let i = 0; i < currentLevel; i++) {
        arrayOfInputBoxes.push(
            <NumberUserInputBox
                inputRef={currentUserInputIndex === i ? inputBoxRef : null}
                boxIndex={i}
                userInputStepBackward={userInputStepBackward}
                userInputStepForward={userInputStepForward}
                userManuallyFocusedOnBox={userManuallyFocusedOnBox}
                key={i}
            />
        );
    }
    return (
        <div
            className={`user-number${
                isDisabled ? ' game-element-not-active' : ''
            }`}
        >
            <Typography variant="overline" display="block" gutterBottom>
                {translate[locale]?.user_input_user_instructions}
            </Typography>
            {/* <KeyboardIcon /> */}

            <span className="user-input-and-icon">
                <div className="array-of-digit-boxes user-input-array">
                    {arrayOfInputBoxes}
                </div>
            </span>
        </div>
    );
};

export default NumberUserInput;
