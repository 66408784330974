import { useEffect, useState, useMemo } from 'react';
import * as constants from '../../redux/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import NumberFromComputerBox from '../NumberFromComputerBox';
import Typography from '@material-ui/core/Typography';
import { translate } from '../../i18n';

const NumberFromComputer = () => {
    const [
        fontSizeOfCurrentlyOpenUserInput,
        setFontSizeOfCurrentlyOpenUserInput,
    ] = useState('');
    const [widthOfCurrentlyOpenUserInput, setWidthOfCurrentlyOpenUserInput] =
        useState('');
    const [heightOfCurrentlyOpenUserInput, setHeightOfCurrentlyOpenUserInput] =
        useState('');

    const currentLevel = useSelector(
        (state: RootState) => state.reducerM2.currentLevel
    );

    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );
    const locale = useSelector((state: RootState) => state.reducerApp.locale);

    const isDisabled = useMemo(
        () =>
            gameStage !== constants.STAGE_01_NUMBER_FROM_COMPUTER_OPEN &&
            gameStage !== constants.STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
        [gameStage]
    );

    let numberFromComputerBoxes: Array<any> = [];

    for (let i = 0; i < currentLevel; i++) {
        numberFromComputerBoxes.push(
            <NumberFromComputerBox
                boxIndex={i}
                key={i}
                maskedHeight={heightOfCurrentlyOpenUserInput}
                maskedWidth={widthOfCurrentlyOpenUserInput}
                maskedFontSize={fontSizeOfCurrentlyOpenUserInput}
            />
        );
    }

    useEffect(() => {
        const element = document.querySelector('.user-input-box-wrapper');

        if (element) {
            setFontSizeOfCurrentlyOpenUserInput(
                getComputedStyle(element).fontSize
            );
            setWidthOfCurrentlyOpenUserInput(getComputedStyle(element).width);
            setHeightOfCurrentlyOpenUserInput(getComputedStyle(element).height);
        }
    }, []);

    return (
        <div
            className={`number-from-computer${
                isDisabled ? ' game-element-not-active' : ''
            }`}
        >
            <Typography variant="overline" display="block" gutterBottom>
                {translate[locale]?.number_from_computer_user_instructions}
            </Typography>
            <span className="number-from-computer-input-and-icon">
                <div
                    className={`array-of-digit-boxes number-from-computer-array`}
                >
                    {numberFromComputerBoxes}
                </div>
            </span>
        </div>
    );
};
export default NumberFromComputer;
