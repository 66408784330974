import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '../../redux/selectors';
import { keywords } from '../../i18n';
import { RootState } from '../../../redux/store';
import Typography from '@material-ui/core/Typography';

const Footer = () => {
    const currentLocale = useSelector((state: RootState) =>
        selectors.selectLocale(state)
    );
    const [kws, setKws] = useState(keywords[currentLocale]);

    useEffect(() => {
        setKws(keywords[currentLocale]);
    }, [currentLocale]);

    const getKeywordsList = (start: number, end: number = Infinity) => {
        return kws.slice(start, end).map((keyword: string, index: number) => (
            <li className="footer-keyword-list-item" key={`kw_${index}`}>
                <a className="footer-keyword-list-item-anchor" href="#top">
                    <Typography variant="overline" gutterBottom component="p">
                        {keyword}
                    </Typography>
                </a>
            </li>
        ));
    };

    return (
        <footer data-cy="footer" className="footer">
            <div className="footer-columns-links">
                <div className="footer-column">
                    <ul className="ul-footer-links">
                        {getKeywordsList(0, 10)}
                    </ul>
                </div>
                <div className="footer-column">
                    <ul className="ul-footer-links">
                        {getKeywordsList(11, 21)}
                    </ul>
                </div>
                <div className="footer-column">
                    <div className="footer-column">
                        <ul className="ul-footer-links">
                            {getKeywordsList(22)}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-site-details">
                <Typography variant="overline" gutterBottom component="div">
                    BrainSquatting.com
                </Typography>
            </div>
        </footer>
    );
};

export default Footer;
