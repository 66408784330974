import * as constants from '../constants';

const actionChangeLocale = (payload: any) => {
    return {
        type: constants.ACTION_APP_CHANGE_LOCALE,
        locale: payload.locale,
    };
};

const actionChangeTheme = () => {
    return {
        type: constants.ACTION_APP_SWITCH_THEME,
    };
};

const actionTogleMenuButton = () => {
    return {
        type: constants.ACTION_APP_TOGGLE_MOBILE_MENU_BUTTON,
    };
};
export { actionChangeLocale, actionChangeTheme, actionTogleMenuButton };
