import { useSelector, useDispatch } from 'react-redux';
import {
    actionTutorialPrevious,
    actionTutorialNext,
} from '../../redux/actions';
import { RootState } from '../../../redux/store';
import { translate } from '../../i18n';
import Typography from '@material-ui/core/Typography';

const TutorialButtonStartGame = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div
            style={{ top: '75% !important' }}
            className="arrow-up-tooltip tutorial-start-button-tooltip z-index-tutorial"
        >
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_01}
            </Typography>
        </div>
    );
};

const TutorialNumberFromComputer = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-down-tooltip tutorial-number-from-computer z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_02}
            </Typography>
        </div>
    );
};

const TutorialButtonCloseNumber = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-up-tooltip tutorial-close-number-button-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_03}
            </Typography>
        </div>
    );
};

const TutorialButtonOpenNumber = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-down-tooltip tutorial-open-number-button-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_04}
            </Typography>
        </div>
    );
};

const TutorialNumberUserInput = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-down-tooltip tutorial-number-user-number-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_05}
            </Typography>
        </div>
    );
};

const TutorialButtonSkip = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-up-tooltip tutorial-button-skip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_06}
            </Typography>
        </div>
    );
};

const TutorialGameSettings = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-up-tooltip tutorial-game-settings-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_07}
            </Typography>
            <NextTutorialStep />
            <PreviousTutorialStep />
        </div>
    );
};

const TutorialGameSettingsNextGame = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-up-tooltip tutorial-game-settings-next-game-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_08}
            </Typography>
            <NextTutorialStep />
            <PreviousTutorialStep />
        </div>
    );
};

const TutorialGameSettingsSequenceLength = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-up-tooltip tutorial-game-settings-sequence-length-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_09}
            </Typography>
            <NextTutorialStep />
            <PreviousTutorialStep />
        </div>
    );
};

const TutorialGameSettingsTimeLimit = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-up-tooltip tutorial-game-settings-time-limit-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_10}
            </Typography>
            <NextTutorialStep />
            <PreviousTutorialStep />
        </div>
    );
};

const TutorialNumberUserInputCorrect = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-down-tooltip tutorial-number-user-number-correct-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_11}
            </Typography>
            <NextTutorialStep />
            <PreviousTutorialStep />
        </div>
    );
};

const TutorialScorePoints = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-down-tooltip tutorial-game-points-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_12}
            </Typography>
            <NextTutorialStep />
            <PreviousTutorialStep />
        </div>
    );
};

const TutorialEnd = () => {
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="arrow-up-tooltip tutorial-end-tooltip z-index-tutorial">
            <Typography variant="body1" gutterBottom component="p">
                {translate[locale]?.tutorial_13}
            </Typography>
        </div>
    );
};

const NextTutorialStep = () => {
    const dispatch = useDispatch();
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <button
            onClick={() => dispatch(actionTutorialNext())}
            className="_game-tutorial-next-step"
        >
            {translate[locale]?.tutorial_next}
        </button>
    );
};

const PreviousTutorialStep = () => {
    const dispatch = useDispatch();
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <button
            onClick={() => dispatch(actionTutorialPrevious())}
            className="_game-tutorial-prev-step"
        >
            {translate[locale]?.tutorial_go_back}
        </button>
    );
};

const TutorialOverlay = () => {
    const isTutorial = useSelector(
        (state: RootState) => state.reducerM2.isTutorial
    );
    return <>{isTutorial && <div className="game-tutorial-modal" />}</>;
};

export {
    TutorialButtonOpenNumber,
    TutorialButtonCloseNumber,
    TutorialButtonStartGame,
    TutorialButtonSkip,
    TutorialGameSettings,
    TutorialGameSettingsNextGame,
    TutorialGameSettingsSequenceLength,
    TutorialGameSettingsTimeLimit,
    TutorialNumberFromComputer,
    TutorialNumberUserInput,
    TutorialNumberUserInputCorrect,
    TutorialScorePoints,
    TutorialEnd,
    TutorialOverlay,
};
