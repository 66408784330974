import { produce } from 'immer';
import * as constants from '../constants';

interface AppState {
    readonly isMobileMenuOpen: boolean;
    readonly isDarkTheme: boolean;
    locale: string;
}

const initialAppState: AppState = {
    isMobileMenuOpen: false,
    isDarkTheme: false,
    locale: 'en',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case constants.ACTION_APP_TOGGLE_MOBILE_MENU_BUTTON:
            draft.isMobileMenuOpen = !draft.isMobileMenuOpen;
            break;
        case constants.ACTION_APP_SWITCH_THEME:
            draft.isDarkTheme = !draft.isDarkTheme;
            break;
        case constants.ACTION_APP_CHANGE_LOCALE:
            draft.locale = action.locale;
            break;
        default:
            break;
    }
}, initialAppState);

export default reducer;
