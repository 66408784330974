import ButtonSkip from '../ButtonSkip';
import ButtonNextNumber from '../ButtonNextNumber';

const SkipAndNextNumber = () => {
    return (
        <div className="skip-and-next-number">
            <ButtonSkip />
            <ButtonNextNumber />
        </div>
    );
};

export default SkipAndNextNumber;
