const ACTION_APP_TOGGLE_MOBILE_MENU_BUTTON =
    'ACTION_APP_TOGGLE_MOBILE_MENU_BUTTON';
const ACTION_APP_SWITCH_THEME = 'ACTION_APP_SWITCH_THEME';
const ACTION_APP_CHANGE_LOCALE = 'ACTION_APP_CHANGE_LOCALE';

export {
    ACTION_APP_TOGGLE_MOBILE_MENU_BUTTON,
    ACTION_APP_SWITCH_THEME,
    ACTION_APP_CHANGE_LOCALE,
};
