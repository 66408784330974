import { produce } from 'immer';
import * as constants from '../constants';

const generateRandomNumber = () => {
    let _rand = Math.floor(Math.random() * 10);
    return _rand;
};

const generateTarget = (length: number) => {
    let _arr = [];
    for (let i = 0; i < length; i++) {
        let _rand = generateRandomNumber();
        _arr.push(_rand.toString());
    }
    return _arr;
};

interface GameState {
    autoMoveToNextGame: boolean;
    autoIncrementalLevels: boolean;
    applyGameTimeLimit: boolean;
    currentLevel: number;
    gameTimeLimit: number;
    timeLimitPhase01_numberFromComputer: number;
    gameStage: string;
    gameHistory: any[];
    gamesPlayed: number;
    isCorrect: boolean;
    isPlaying: boolean;
    isGame: boolean;
    isNumberFromComputerOpen: boolean;
    isPaused: boolean;
    isSettingsOpen: boolean;
    isHowToPlayOpen: boolean;
    isSkipped: boolean;
    isTutorial: boolean;
    isUserInputOpen: boolean;
    numberFromComputer: string[];
    gameProgress: boolean[];
    points: number;
    pointsWithinLevel: number;
    maxPointsWithinLevel: number;
    secondsElapsed: number;
    tutorialStep: number;
    userInput: string;
}

const initialGameState: GameState = {
    autoMoveToNextGame: true,
    autoIncrementalLevels: true,
    applyGameTimeLimit: true,
    currentLevel: 4,
    gameStage: '',
    gameTimeLimit: 30,
    timeLimitPhase01_numberFromComputer: 30,
    gameHistory: [],
    gamesPlayed: 0,
    isCorrect: false,
    isGame: false,
    isNumberFromComputerOpen: true,
    isPaused: false,
    isPlaying: false,
    isSettingsOpen: false,
    isHowToPlayOpen: false,
    isSkipped: false,
    isTutorial: false,
    isUserInputOpen: false,
    numberFromComputer: [],
    gameProgress: [],
    points: 0,
    pointsWithinLevel: 0,
    maxPointsWithinLevel: 2,
    secondsElapsed: 0,
    tutorialStep: 0,
    userInput: '',
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        /** GLOBAL PLAYING, FINISH_PLAYING IS LIKE RESET */
        case constants.START_PLAYING:
            draft.isPlaying = true;
            draft.points = 0;
            draft.gamesPlayed = 0;
            break;
        case constants.FINISH_PLAYING:
            return initialGameState;
        case constants.FLAG_GAME_NEW:
            draft.isCorrect = false;
            draft.secondsElapsed = 0;
            if (draft.pointsWithinLevel >= draft.maxPointsWithinLevel - 1) {
                draft.currentLevel = draft.currentLevel + 1;
                draft.pointsWithinLevel = 0;
                localStorage.setItem(
                    'gameSettings',
                    JSON.stringify({
                        applyGameTimeLimit: draft.applyGameTimeLimit,
                        autoIncrementalLevels: draft.autoIncrementalLevels,
                        autoMoveToNextGame: draft.autoMoveToNextGame,
                        currentLevel: draft.currentLevel,
                        gameTimeLimit: draft.gameTimeLimit,
                    })
                );
            } else {
                draft.pointsWithinLevel = draft.pointsWithinLevel + 1;
            }
            draft.numberFromComputer = draft.isTutorial
                ? ['1', '2', '3', '4', '5']
                : generateTarget(draft.currentLevel);
            draft.isNumberFromComputerOpen = true;
            draft.userInput = '';
            draft.isUserInputOpen = false;
            draft.isSkipped = false;
            draft.gameStage = constants.STAGE_01_NUMBER_FROM_COMPUTER_OPEN;
            draft.gameProgress = Array(draft.currentLevel).fill(false);
            break;
        case constants.FLAG_GAME_END:
            draft.gamesPlayed = draft.gamesPlayed + 1;
            if (draft.isCorrect === true) draft.points = draft.points + 1;
            draft.gameStage = constants.STAGE_04_COMPARE_RESULT_AND_FINISH_GAME;
            break;
        case constants.SINGLE_CORRECT_INPUT:
            draft.gameProgress[action.correctInputIndex] = action.isCorrect;
            break;
        case constants.USER_TYPED_SOMETHING:
            draft.userInput = action.userInput
                ? action.userInput.match(/[0-9]/g).join(',')
                : '';
            break;
        case constants.FLAG_USER_INPUT_IS_CORRECT:
            draft.isCorrect = true;
            draft.gameStage = constants.STAGE_04_COMPARE_RESULT_AND_FINISH_GAME;
            break;
        case constants.USER_PRESS_SKIP_NUMBER:
            draft.gameStage = constants.STAGE_04_COMPARE_RESULT_AND_FINISH_GAME;
            draft.isSkipped = true;
            draft.isNumberFromComputerOpen = true;
            break;
        case constants.TOGGLE_NUMBER_FROM_COMPUTER:
            draft.isNumberFromComputerOpen = action.isOpen;
            break;
        /** GAME SETTINGS */
        case constants.SETTINGS_SAVE:
            draft.applyGameTimeLimit = action.applyGameTimeLimit;
            draft.autoIncrementalLevels = action.autoIncrementalLevels;
            draft.autoMoveToNextGame = action.autoMoveToNextGame;
            draft.currentLevel =
                parseInt(action.currentLevel) || draft.currentLevel;
            draft.gameTimeLimit =
                parseInt(action.gameTimeLimit) || draft.gameTimeLimit;
            break;
        case constants.SETTINGS_TOGGLE_MENU:
            draft.isSettingsOpen = action.isOpen;
            break;
        case constants.TOGGLE_HOW_TO_PLAY:
            draft.isHowToPlayOpen = action.isOpen;
            break;
        case constants.USER_PRESS_CLOSE_NUMBER_FROM_COMPUTER:
            draft.isNumberFromComputerOpen = false;
            draft.gameStage = constants.STAGE_02_NUMBER_FROM_COMPUTER_CLOSED;
            break;
        case constants.USER_PRESS_OPEN_INPUT_BUTTON:
            draft.isUserInputOpen = true;
            draft.gameStage = constants.STAGE_03_USER_INPUT_OPEN;
            break;
        case constants.USER_PRESS_NEXT_NUMBER_BUTTON:
            draft.gameStage = null;
            break;
        case constants.PAUSE_BUTTON_PRESSED:
            draft.isPaused = action.isPaused;
            break;
        /** TUTORIAL */
        case constants.USER_PRESS_HOW_TO_PLAY:
            draft.isTutorial = !draft.isTutorial;
            draft.tutorialStep = 0;
            break;
        case constants.TUTORIAL_PREVIOUS_STEP:
            draft.tutorialStep = draft.tutorialStep - 1;
            break;
        case constants.TUTORIAL_NEXT_STEP:
            draft.tutorialStep = draft.tutorialStep + 1;
            break;

        default:
            break;
    }
}, initialGameState);

export default reducer;
