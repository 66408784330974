import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import * as selectors from '../../redux/selectors';
import { routes, routeHomePage } from '../../../config';
import { NavLink } from 'react-router-dom';
import MobileMenuItem from '../MobileMenuItem';
import { translate } from '../../i18n';

const MobileMenu = () => {
    const currentLocale = useSelector((state: RootState) =>
        selectors.selectLocale(state)
    );

    return (
        <div
            className="mobile-menu"
            data-cy="mobile-menu"
            itemScope /* itemtype="http://schema.org/BreadcrumbList" */
        >
            <NavLink
                to={currentLocale === 'en' ? routeHomePage.path : ''}
                key={`SidebarMenuItem___home`}
            >
                <MobileMenuItem
                    icon={routeHomePage.icon}
                    title={routeHomePage.title}
                />
            </NavLink>

            {routes.map((route) => {
                if (route.icon) {
                    return (
                        <NavLink
                            to={route.path}
                            className="mobile-nav-link"
                            key={`MobileMenuItem__${route.title}`}
                        >
                            <MobileMenuItem
                                icon={route.icon}
                                // title={route.title}
                                title={
                                    translate[currentLocale][route.serial].title
                                }
                            />
                        </NavLink>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
};
export default MobileMenu;
