import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';

type MobileMenuItemProps = {
    icon: any;
    title: string;
};

const MobileMenuItem = (props: MobileMenuItemProps) => {
    const dispatch = useDispatch();

    return (
        <div
            className="mobile-menu-item"
            data-cy="mobile-menu-item"
            onClick={() => dispatch(actions.actionTogleMenuButton())}
        >
            <div className="mobile-menu-item-icon">{props.icon}</div>

            <div className="mobile-menu-item-title" itemProp="game">
                {props.title}
            </div>
        </div>
    );
};

export default MobileMenuItem;
