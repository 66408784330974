import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { actionOpenNumber, actionTutorialNext } from '../../redux/actions';
import * as constants from '../../redux/constants';
import { TutorialButtonOpenNumber } from '../Tutorials';
import { translate } from '../../i18n';
import LockOpen from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';

const ButtonOpenNumber = () => {
    const dispatch = useDispatch();
    const tutorialStep = useSelector(
        (state: RootState) => state.reducerM2.tutorialStep
    );
    const isTutorial = useSelector(
        (state: RootState) => state.reducerM2.isTutorial
    );
    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );
    const isDisabled = useMemo(
        () => gameStage !== constants.STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
        [gameStage]
    );
    const locale = useSelector((state: RootState) => state.reducerApp.locale);

    return (
        <div className="open-number">
            <button
                onClick={() => {
                    dispatch(actionOpenNumber());
                    if (isTutorial) dispatch(actionTutorialNext());
                }}
                className={`open-number-button${
                    isTutorial ? ' z-index-tutorial' : ''
                }${isDisabled ? ' game-element-not-active' : ''}`}
                disabled={isDisabled}
            >
                <LockOpen />

                <Typography variant="button" display="block" gutterBottom>
                    {translate[locale]?.enter_number}
                </Typography>
            </button>
            {isTutorial && tutorialStep === 2 && <TutorialButtonOpenNumber />}
        </div>
    );
};

export default ButtonOpenNumber;
