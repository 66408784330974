interface I18nMenuScope {
    title: string;
    description: string;
}

interface I18nScopeModule {
    switch_to_dark_theme: string;
    switch_to_bright_theme: string;
    bcp47: string;
    main: I18nMenuScope;
    m2: I18nMenuScope;
    m3: I18nMenuScope;
    faq: I18nMenuScope;
}

const en: Required<I18nScopeModule> = {
    switch_to_dark_theme: 'switch website to dark background',
    switch_to_bright_theme: 'switch website to light background',
    bcp47: 'en-US, en-AU, en-GB, en-IE, en-ZA',
    main: {
        title: 'Games for improving your memory',
        description:
            'Simple educational games which will improve your shot-term and long-term memory and recommended for all ages',
    },
    m2: {
        title: 'Memorize a sequence',
        description:
            'Practice your memory by memorizing different sequences of numbers with incrementing difficulty levels',
    },
    m3: {
        title: 'Memorize a sequence',
        description:
            'Practice your memory by memorizing different sequences of numbers with incrementing difficulty levels',
    },
    faq: {
        title: 'FAQ',
        description: 'FAQ',
    },
};

const ru: Required<I18nScopeModule> = {
    bcp47: 'ru-RU',
    switch_to_dark_theme: 'переключить сайт на тёмный фон',
    switch_to_bright_theme: 'переключить сайт на светлый фон',
    main: {
        title: 'Игры для улучшения памяти',
        description:
            'Простые развивающие игры, которые улучшат вашу кратковременную и долговременную память и рекомендуются для всех возрастов.',
    },
    m2: {
        title: 'Запомните последовательность',
        description:
            'Практикуйте свою память, запоминая различные последовательности чисел с возрастающими уровнями сложности',
    },
    m3: {
        title: 'Запомните последовательность',
        description:
            'Практикуйте свою память, запоминая различные последовательности чисел с возрастающими уровнями сложности',
    },
    faq: {
        title: 'Вопросы-Ответы',
        description: 'Вопросы-Ответы',
    },
};

const es: Required<I18nScopeModule> = {
    bcp47: 'es-ES, es-MX',
    switch_to_dark_theme: 'cambiar el sitio web a fondo oscuro',
    switch_to_bright_theme: 'cambiar el sitio web a fondo claro',
    main: {
        title: 'Juegos para mejorar tu memoria',
        description:
            'Sencillos juegos educativos que mejorarán tu memoria a corto y largo plazo y recomendados para todas las edades',
    },
    m2: {
        title: 'Memoriza una secuencia',
        description:
            'Practica tu memoria memorizando diferentes secuencias de números con niveles de dificultad crecientes',
    },
    m3: {
        title: 'Memoriza una secuencia',
        description:
            'Practica tu memoria memorizando diferentes secuencias de números con niveles de dificultad crecientes',
    },
    faq: {
        title: 'Preguntas más frecuentes',
        description: 'Preguntas más frecuentes',
    },
};

const pt: Required<I18nScopeModule> = {
    bcp47: 'pt-PT, pt-BR',
    switch_to_dark_theme: 'mudar site para fundo escuro',
    switch_to_bright_theme: 'mudar o site para fundo claro',
    main: {
        title: 'Jogos para melhorar a memória',
        description:
            'Jogos educativos simples que melhorarão sua memória de curto e longo prazo e recomendados para todas as idades',
    },
    m2: {
        title: 'Memorize uma sequência',
        description:
            'Pratique sua memória memorizando diferentes sequências de números com níveis de dificuldade crescentes',
    },
    m3: {
        title: 'Memorize uma sequência',
        description:
            'Pratique sua memória memorizando diferentes sequências de números com níveis de dificuldade crescentes',
    },
    faq: {
        title: 'Perguntas frequentes',
        description: 'Perguntas frequentes',
    },
};

const fr: Required<I18nScopeModule> = {
    bcp47: 'fr-FR, fr-CA',
    switch_to_dark_theme: 'basculer le site Web sur un fond sombre',
    switch_to_bright_theme: 'passer le site Web en arrière-plan clair',
    main: {
        title: 'Jeux pour améliorer votre mémoire',
        description:
            'Des jeux éducatifs simples qui amélioreront votre mémoire à long terme et à long terme et recommandés pour tous les âges',
    },
    m2: {
        title: 'Mémoriser une séquence',
        description:
            'Entraînez votre mémoire en mémorisant différentes séquences de nombres avec des niveaux de difficulté croissants',
    },
    m3: {
        title: 'Mémoriser une séquence',
        description:
            'Entraînez votre mémoire en mémorisant différentes séquences de nombres avec des niveaux de difficulté croissants',
    },
    faq: {
        title: 'FAQ',
        description: 'FAQ',
    },
};

const translate: any = {
    en: en,
    fr: fr,
    es: es,
    pt: pt,
    ru: ru,
};

const keywords: any = {
    en: [
        'brain exercises for memory',
        'games to improve memory and concentration',
        'games to improve concentration',
        'brain booster games',
        'memory training games',
        'brain improvement games',
        'games to help memory',
        'memory improvement tips games',
        'memory game app',
        'games to increase concentration',
        'memory training app',
        'games to train your brain',
        'memory train',
        'best memory game app',
        'games to increase brain power',
        'brain games to improve memory',
        'games to stimulate your brain',
        'games to improve working memory in adults',
        'cognitive training games',
        'best games to improve memory',
        'games to improve working memory',
        'best memory training app',
        'games that help your brain',
        'that improve brain function',
    ],
    fr: [
        'exercices cérébraux pour la mémoire',

        'jeux pour améliorer la mémoire et la concentration',

        'jeux pour améliorer la concentration',

        'jeux de stimulation cérébrale',

        'jeux d`entraînement de la mémoire',

        'jeux d`amélioration du cerveau',

        'jeux d`aide à la mémoire',

        'jeux de conseils d`amélioration de la mémoire',

        'application de jeu de mémoire',

        'jeux pour augmenter la concentration',

        'application d`entraînement de la mémoire',

        'jeux pour entraîner votre cerveau',

        'train de mémoire',

        'meilleure application de jeu de mémoire',

        'jeux pour augmenter la puissance du cerveau',

        'jeux de réflexion pour améliorer la mémoire',

        'des jeux pour stimuler ton cerveau',

        'jeux pour améliorer la mémoire de travail chez les adultes',

        'jeux d`entraînement cognitif',

        'meilleurs jeux pour améliorer la mémoire',

        'jeux pour améliorer la mémoire de travail',

        'meilleure application d`entraînement de la mémoire',

        'des jeux qui aident votre cerveau',

        'qui améliorent la fonction cérébrale',
    ],
    pt: [
        'exercícios cerebrais para memória',

        'jogos para melhorar a memória e a concentração',

        'jogos para melhorar a concentração',

        'jogos de reforço cerebral',

        'jogos de treino de memória',

        'jogos de aprimoramento do cérebro',

        'jogos para ajudar a memória',

        'jogos de dicas de melhoria de memória',

        'aplicativo de jogo de memória',

        'jogos para aumentar a concentração',

        'aplicativo de treinamento de memória',

        'jogos para treinar seu cérebro',

        'trem da memória',

        'melhor aplicativo de jogo de memória',

        'jogos para aumentar o poder do cérebro',

        'jogos cerebrais para melhorar a memória',

        'jogos para estimular seu cérebro',

        'jogos para melhorar a memória de trabalho em adultos',

        'jogos de treinamento cognitivo',

        'melhores jogos para melhorar a memória',

        'jogos para melhorar a memória de trabalho',

        'melhor aplicativo de treinamento de memória',

        'jogos que ajudam seu cérebro',

        'que melhoram a função cerebral',
    ],
    es: [
        'ejercicios cerebrales para la memoria',

        'juegos para mejorar la memoria y la concentración',

        'juegos para mejorar la concentración',

        'juegos de refuerzo mental',

        'juegos de entrenamiento de memoria',

        'juegos de mejora del cerebro',

        'juegos para ayudar a la memoria',

        'juegos de consejos para mejorar la memoria',

        'aplicación de juego de memoria',

        'juegos para aumentar la concentración',

        'aplicación de entrenamiento de memoria',

        'juegos para entrenar tu cerebro',

        'tren de la memoria',

        'mejor aplicación de juegos de memoria',

        'juegos para aumentar el poder del cerebro',

        'juegos mentales para mejorar la memoria',

        'juegos para estimular tu cerebro',

        'juegos para mejorar la memoria de trabajo en adultos',

        'juegos de entrenamiento cognitivo',

        'mejores juegos para mejorar la memoria',

        'juegos para mejorar la memoria de trabajo',

        'mejor aplicación de entrenamiento de memoria',

        'juegos que ayudan a tu cerebro',

        'que mejoran la función cerebral',
    ],
    ru: [
        'мозговые упражнения для памяти',

        'игры для улучшения памяти и концентрации внимания',

        'игры для улучшения концентрации',

        'развивающие мозг игры',

        'игры для тренировки памяти',

        'игры для развития мозга',

        'игры для памяти',

        'игры с советами по улучшению памяти',

        'приложение для игры на память',

        'игры для повышения концентрации',

        'приложение для тренировки памяти',

        'игры для тренировки мозга',

        'поезд памяти',

        'лучшее приложение для игры на память',

        'игры для увеличения мощности мозга',

        'интеллектуальные игры для улучшения памяти',

        'игры для стимуляции мозга',

        'игры для улучшения рабочей памяти у взрослых',

        'познавательные обучающие игры',

        'лучшие игры для улучшения памяти',

        'игры для улучшения рабочей памяти',

        'лучшее приложение для тренировки памяти',

        'игры, которые помогут вашему мозгу',

        'которые улучшают работу мозга',
    ],
};

export { translate, keywords };
