/** JUST NOMINAL - A SINGLE GAME SPLIT TO SEVERAL PHASES */
const STAGE_01_NUMBER_FROM_COMPUTER_OPEN = 'STAGE_01_NUMBER_FROM_COMPUTER_OPEN';
const STAGE_02_NUMBER_FROM_COMPUTER_CLOSED =
    'STAGE_02_NUMBER_FROM_COMPUTER_CLOSED';
const STAGE_03_USER_INPUT_OPEN = 'STAGE_03_USER_INPUT_OPEN';
const STAGE_04_COMPARE_RESULT_AND_FINISH_GAME =
    'STAGE_04_COMPARE_RESULT_AND_FINISH_GAME';

/** GAME PHASES */
const TIMEOUT_STAGE_01_NUMBER_FROM_COMPUTER_OPEN =
    'TIMEOUT_STAGE_01_NUMBER_FROM_COMPUTER_OPEN';
const TIMEOUT_STAGE_02_NUMBER_FROM_COMPUTER_CLOSED =
    'TIMEOUT_STAGE_02_NUMBER_FROM_COMPUTER_CLOSED';
const TIMEOUT_STAGE_03_USER_INPUT_OPEN = 'TIMEOUT_STAGE_03_USER_INPUT_OPEN';
const TIMEOUT_STAGE_04_COMPARE_RESULT_AND_FINISH_GAME =
    'TIMEOUT_STAGE_04_COMPARE_RESULT_AND_FINISH_GAME';

const START_PLAYING = 'START_PLAYING';
const FINISH_PLAYING = 'FINISH_PLAYING';

const USER_PRESS_OPEN_INPUT_BUTTON = 'USER_PRESS_OPEN_INPUT_BUTTON';
const USER_TYPED_SOMETHING = 'USER_TYPED_SOMETHING';
const USER_PRESS_SKIP_NUMBER = 'USER_PRESS_SKIP_NUMBER';
const FLAG_USER_INPUT_IS_CORRECT = 'FLAG_USER_INPUT_IS_CORRECT';

/** NEW_GAME = a new number... */
/** END_GAME = end for a single nuber... */
const FLAG_GAME_NEW = 'FLAG_GAME_NEW';
const FLAG_GAME_END = 'FLAG_GAME_END';
const FLAG_UPDATE_SETTINGS = 'FLAG_UPDATE_SETTINGS';
const USER_PRESS_HOW_TO_PLAY = 'USER_PRESS_HOW_TO_PLAY';
const TUTORIAL_PREVIOUS_STEP = 'TUTORIAL_PREVIOUS_STEP';
const TUTORIAL_NEXT_STEP = 'TUTORIAL_NEXT_STEP';
const SETTINGS_TOGGLE_MENU = 'SETTINGS_TOGGLE_MENU';
const SETTINGS_SAVE = 'SETTINGS_SAVE';
const TOGGLE_NUMBER_FROM_COMPUTER = 'TOGGLE_NUMBER_FROM_COMPUTER';
const TIMEOUT_USER_INPUT_TIMEOUT = 'TIMEOUT_USER_INPUT_TIMEOUT';
const USER_PRESS_NEXT_NUMBER_BUTTON = 'USER_PRESS_NEXT_NUMBER_BUTTON';
const USER_PRESS_CLOSE_NUMBER_FROM_COMPUTER =
    'USER_PRESS_CLOSE_NUMBER_FROM_COMPUTER';
const TOGGLE_HOW_TO_PLAY = 'TOGGLE_HOW_TO_PLAY';
const PAUSE_BUTTON_PRESSED = 'PAUSE_BUTTON_PRESSED';
const SINGLE_CORRECT_INPUT = 'SINGLE_CORRECT_INPUT';
export {
    FINISH_PLAYING,
    START_PLAYING,
    FLAG_GAME_NEW,
    FLAG_GAME_END,
    FLAG_USER_INPUT_IS_CORRECT,
    FLAG_UPDATE_SETTINGS,
    USER_PRESS_HOW_TO_PLAY,
    USER_PRESS_OPEN_INPUT_BUTTON,
    USER_PRESS_SKIP_NUMBER,
    USER_PRESS_NEXT_NUMBER_BUTTON,
    USER_PRESS_CLOSE_NUMBER_FROM_COMPUTER,
    USER_TYPED_SOMETHING,
    TUTORIAL_PREVIOUS_STEP,
    TUTORIAL_NEXT_STEP,
    SETTINGS_TOGGLE_MENU,
    SETTINGS_SAVE,
    TOGGLE_NUMBER_FROM_COMPUTER,
    TIMEOUT_USER_INPUT_TIMEOUT,
    /** PROBABLY NO NEED IN RESET TIMER ACTIONS */
    /** PHASES NOT ACTIONS */
    STAGE_01_NUMBER_FROM_COMPUTER_OPEN,
    STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
    STAGE_03_USER_INPUT_OPEN,
    STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
    TIMEOUT_STAGE_01_NUMBER_FROM_COMPUTER_OPEN,
    TIMEOUT_STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
    TIMEOUT_STAGE_03_USER_INPUT_OPEN,
    TIMEOUT_STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
    PAUSE_BUTTON_PRESSED,
    SINGLE_CORRECT_INPUT,
    TOGGLE_HOW_TO_PLAY,
};
