import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';

/** REMINDER:
 *  THERE ARE SEVERAL STAGES IN THE GAME;
 *  MOVING TO DIFFERENT STAGE CAUSED BY USERS ACTIONS (OR TIMEOUT)
 */
import {
    TIMEOUT_STAGE_01_NUMBER_FROM_COMPUTER_OPEN,
    TIMEOUT_STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
    TIMEOUT_STAGE_03_USER_INPUT_OPEN,
    TIMEOUT_STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
    STAGE_01_NUMBER_FROM_COMPUTER_OPEN,
    STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
    STAGE_03_USER_INPUT_OPEN,
    STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
} from '../../redux/constants';

const Stopper = () => {
    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );
    const isPaused = useSelector(
        (state: RootState) => state.reducerM2.isPaused
    );

    const gameTimeLimit = useSelector(
        (state: RootState) => state.reducerM2.gameTimeLimit
    );
    const isCorrect = useSelector(
        (state: RootState) => state.reducerM2.isCorrect
    );
    const intervalRef = useRef<any>();
    const [secondsRemaining, setSecondsRemaining] = useState(gameTimeLimit);

    const dispatch = useDispatch();

    useEffect(() => {
        setSecondsRemaining(gameTimeLimit);
    }, [gameStage, gameTimeLimit]);

    useEffect(() => {
        const doActionAfterTimeout = () => {
            switch (gameStage) {
                case STAGE_01_NUMBER_FROM_COMPUTER_OPEN:
                    dispatch({
                        type: TIMEOUT_STAGE_01_NUMBER_FROM_COMPUTER_OPEN,
                    });
                    break;
                case STAGE_02_NUMBER_FROM_COMPUTER_CLOSED:
                    dispatch({
                        type: TIMEOUT_STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
                    });
                    break;
                case STAGE_03_USER_INPUT_OPEN:
                    dispatch({ type: TIMEOUT_STAGE_03_USER_INPUT_OPEN });
                    break;
                case STAGE_04_COMPARE_RESULT_AND_FINISH_GAME:
                    dispatch({
                        type: TIMEOUT_STAGE_04_COMPARE_RESULT_AND_FINISH_GAME,
                    });
                    break;
                default:
                    break;
            }
        };

        if (secondsRemaining > 0 && gameStage) {
            const id = setInterval(() => {
                if (!isPaused) setSecondsRemaining(secondsRemaining - 1);
            }, 1000);
            intervalRef.current = id;
        } else {
            if (gameStage) {
                doActionAfterTimeout();
                setSecondsRemaining(gameTimeLimit);
            }
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [dispatch, secondsRemaining, gameStage, isPaused, gameTimeLimit]);

    return (
        <div className="game-stopper" id="game-stopper">
            {!isCorrect && (
                <div className="stopper-time-area">
                    <span className="stopper-digit stopper-hours">
                        {Math.floor(secondsRemaining / 3600) > 9
                            ? Math.floor(secondsRemaining / 3600)
                            : `0${Math.floor(secondsRemaining / 3600)}`}
                    </span>
                    :
                    <span className="stopper-digit stopper-hours">
                        {Math.floor(secondsRemaining / 60) % 60 > 9
                            ? Math.floor(secondsRemaining / 60) % 60
                            : `0${Math.floor(secondsRemaining / 60) % 60}`}
                    </span>
                    :
                    <span className="stopper-digit stopper-seconds">
                        {secondsRemaining % 60 > 9
                            ? secondsRemaining % 60
                            : `0${secondsRemaining % 60}`}
                    </span>
                </div>
            )}
        </div>
    );
};

export default Stopper;
