import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type Props = {
    boxIndex: number;
    maskedHeight: string;
    maskedWidth: string;
    maskedFontSize: string;
};

const NumberFromComputerBox = ({
    boxIndex,
    maskedWidth,
    maskedHeight,
    maskedFontSize,
}: Props) => {
    const numberFromComputer = useSelector(
        (state: RootState) => state.reducerM2.numberFromComputer
    );
    const gameProgress = useSelector(
        (state: RootState) => state.reducerM2.gameProgress
    );
    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );

    return (
        <div>
            {gameStage === 'STAGE_01_NUMBER_FROM_COMPUTER_OPEN' ||
            gameStage === 'STAGE_04_COMPARE_RESULT_AND_FINISH_GAME' ||
            gameProgress[boxIndex] ? (
                <input
                    readOnly
                    className="single-digit-box _number-from-computer"
                    type="text"
                    value={numberFromComputer[boxIndex] || ''}
                />
            ) : (
                <div
                    className="single-digit-box _number-from-computer _masked"
                    dangerouslySetInnerHTML={{ __html: '&#10062;' }}
                    style={{
                        width: maskedWidth ? maskedWidth : 'initial',
                        fontSize: maskedFontSize ? maskedFontSize : 'initial',
                        height: maskedHeight,
                        lineHeight: maskedHeight,
                    }}
                />
            )}
        </div>
    );
};

export default NumberFromComputerBox;
