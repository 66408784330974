import React, { useState, useEffect } from 'react';

interface Props {
    text: string;
}

const TypingEffect: React.FC<Props> = ({ text }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        let index = 0;
        const interval = setInterval(() => {
            setDisplayedText(text.substring(0, index));
            index++;
            if (index > text.length) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [text]);

    return <span>{displayedText}</span>;
};

export default TypingEffect;
