import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Stopper from '../Stopper';
import { actionStartPlaying } from '../../redux/actions';
import GameSystemMessage from '../GameSystemMessage';
import PlayArrow from '@material-ui/icons/PlayArrow';
//FiberManualRecordTwoTone
const ButtonStartGameWithNotifications = () => {
    const dispatch = useDispatch();

    const isPlaying = useSelector(
        (state: RootState) => state.reducerM2.isPlaying
    );

    return (
        <div
            className={`game-notifications ${
                isPlaying ? 'is-playing' : 'is-not-playing'
            }`}
            onClick={() => {
                if (isPlaying) return;
                dispatch(actionStartPlaying());
            }}
        >
            <div
                className={`main-button-element main-button-game-stopper ${
                    isPlaying ? 'is-playing' : 'is-not-playing'
                }`}
            >
                <Stopper />
            </div>
            <div
                className={`main-button-element main-button-game-system-message ${
                    isPlaying ? 'is-playing' : 'is-not-playing'
                }`}
            >
                <GameSystemMessage />
            </div>

            <div
                className={`main-button-element main-button-game-status-icon ${
                    isPlaying ? 'is-playing' : 'is-not-playing'
                }`}
            >
                <PlayArrow />
            </div>
            {/* <PlayArrow
                className={`game-status-icon ${
                    isPlaying ? 'is-playing' : 'is-not-playing'
                }`}
            /> */}
        </div>
    );
};

export default ButtonStartGameWithNotifications;
