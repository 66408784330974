import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routesGlobalLocale, routes } from './config';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ErrorPage from './error';
ReactDOM.render(
    <Router>
        <Provider store={store}>
            <Routes>
                {routesGlobalLocale.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={<route.main />}
                        errorElement={<ErrorPage />}
                    >
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                element={<route.main />}
                                errorElement={<ErrorPage />}
                            />
                        ))}
                    </Route>
                ))}
            </Routes>
        </Provider>
    </Router>,
    document.getElementById('root')
);

// const perfData = window.performance.timing;
// const pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
// const connectTime = perfData.responseEnd - perfData.requestStart;
// const renderTime = perfData.domComplete - perfData.domLoading;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register({});
