import { useSelector, useDispatch } from 'react-redux';
import { actionToggleHowToPlay } from '../../redux/actions';
import { RootState } from '../../../redux/store';

import { translate } from '../../i18n';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const HowToPlay = () => {
    const dispatch = useDispatch();
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );
    const isHowToPlayOpen: any = useSelector(
        (state: RootState) => state.reducerM2.isHowToPlayOpen
    );

    return (
        <div
            className={`how-to-play-modal${
                !isHowToPlayOpen ? ' display-none' : ''
            }`}
        >
            <Typography variant="subtitle2" gutterBottom component="h2">
                {translate[locale]?.title}: {translate[locale]?.how_to_play}
            </Typography>
            <div className="how-to-play-modal-description">
                <Typography variant="body1" gutterBottom component="h2">
                    {translate[locale]?.how_to_play_details}
                </Typography>
                <div className="how-to-play-modal-close">
                    <button
                        className="button-game-close-settings"
                        onClick={() => {
                            dispatch(
                                actionToggleHowToPlay({
                                    isOpen: false,
                                })
                            );
                        }}
                        title={translate[locale]?.how_to_play_close}
                    >
                        <CloseIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HowToPlay;
