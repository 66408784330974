/**
 * to do:
 * what with the text input numbers turn to text
 */
import React, { useReducer, useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import {
    actionSaveGameSettings,
    actionToggleSettings,
} from '../../redux/actions';

import {
    TutorialGameSettingsNextGame,
    TutorialGameSettingsSequenceLength,
    TutorialGameSettingsTimeLimit,
} from '../Tutorials';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { translate } from '../../i18n';

interface Props {
    _applyGameTimeLimit: boolean;
    _currentLevel: number;
    _gameTimeLimit: number;
    _autoMoveToNextGame: boolean;
    _autoIncrementalLevels: boolean;
    isTutorial: boolean;
    tutorialStep: number;
    _isErrorInputGameTime: boolean;
    _isErrorInputLevel: boolean;
    isSettingsOpen: boolean;
}
const mapStateToProps = (state: RootState) => {
    return {
        _currentLevel: state.reducerM2.currentLevel,
        _gameTimeLimit: state.reducerM2.gameTimeLimit,
        _autoMoveToNextGame: state.reducerM2.autoMoveToNextGame,
        _autoIncrementalLevels: state.reducerM2.autoIncrementalLevels,
        _applyGameTimeLimit: state.reducerM2.applyGameTimeLimit,
        _isErrorInputGameTime: false,
        _isErrorInputLevel: false,
        isTutorial: state.reducerM2.isTutorial,
        tutorialStep: state.reducerM2.tutorialStep,
        isSettingsOpen: state.reducerM2.isSettingsOpen,
    };
};

const Settings: React.FC<Props> = ({
    _currentLevel,
    _gameTimeLimit,
    _autoMoveToNextGame,
    _autoIncrementalLevels,
    _applyGameTimeLimit,
    _isErrorInputGameTime,
    _isErrorInputLevel,
    isTutorial,
    tutorialStep,
    isSettingsOpen,
}) => {
    // const _gameTimeLimit = useSelector(
    //     (state: RootState) => state.reducerM2.gameTimeLimit
    // );
    const [gameSettings, setGameSettings] = useReducer(
        (gameSettings: any, setting: any) => {
            switch (setting.type) {
                case 'gameStartingLevel':
                    return {
                        ...gameSettings,
                        currentLevel: setting.value,
                        isErrorInputLevel: !/^\d+$/.test(setting.value),
                    };
                case 'autoMoveToNextGame':
                    return {
                        ...gameSettings,
                        autoMoveToNextGame: setting.value,
                    };
                case 'autoIncrementalLevels':
                    return {
                        ...gameSettings,
                        autoIncrementalLevels: setting.value,
                    };
                case 'applyGameTimeLimit':
                    return {
                        ...gameSettings,
                        applyGameTimeLimit: setting.value,
                    };
                case 'gameTimeLimit':
                    return {
                        ...gameSettings,
                        gameTimeLimit: setting.value,
                        isErrorInputGameTime: !/^\d+$/.test(setting.value),
                    };

                default:
                    throw new Error();
            }
        },
        {
            autoMoveToNextGame: _autoMoveToNextGame,
            autoIncrementalLevels: _autoIncrementalLevels,
            applyGameTimeLimit: _applyGameTimeLimit,
            currentLevel: _currentLevel,
            gameTimeLimit: _gameTimeLimit,
            isErrorInputGameTime: _isErrorInputGameTime,
            isErrorInputLevel: _isErrorInputLevel,
            isSettingsOpen: isSettingsOpen,
        }
    );

    const dispatch = useDispatch();
    const focusRef = useRef<any>(null);

    const [anySettingsChanged, setAnySettingsChanged] = useState(false);
    const [newSettingsSavedShowMessage, setNewSettingsSavedShowMessage] =
        useState(false);
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    /** loading game settings from local storage and updating the game reducer and settings widget */
    /** when FINISH playing the counters reset to OG reducer values */
    useEffect(() => {
        const settingsFromLocalStorage = localStorage.getItem('gameSettings');

        if (settingsFromLocalStorage) {
            const _settingsFromLocalStorage = JSON.parse(
                settingsFromLocalStorage
            );

            if (
                _settingsFromLocalStorage.applyGameTimeLimit &&
                _settingsFromLocalStorage.autoIncrementalLevels &&
                _settingsFromLocalStorage.autoMoveToNextGame &&
                _settingsFromLocalStorage.currentLevel &&
                _settingsFromLocalStorage.gameTimeLimit
            ) {
                setGameSettings({
                    type: 'applyGameTimeLimit',
                    value: _settingsFromLocalStorage.applyGameTimeLimit,
                });
                setGameSettings({
                    type: 'autoMoveToNextGame',
                    value: _settingsFromLocalStorage.autoMoveToNextGame,
                });
                setGameSettings({
                    type: 'autoIncrementalLevels',
                    value: _settingsFromLocalStorage.autoIncrementalLevels,
                });
                setGameSettings({
                    type: 'gameStartingLevel',
                    value: _settingsFromLocalStorage.currentLevel,
                });
                setGameSettings({
                    type: 'gameTimeLimit',
                    value: _settingsFromLocalStorage.gameTimeLimit,
                });
                dispatch(actionSaveGameSettings(_settingsFromLocalStorage));
            }
        }
    }, [dispatch]);

    return (
        <div
            className={`game-settings-modal ${
                !isSettingsOpen ? 'display-none' : ''
            }`}
            ref={isSettingsOpen ? focusRef : null}
            tabIndex={-1}
        >
            <Typography variant="body2" gutterBottom component="p">
                {translate[locale]?.settings_title}
            </Typography>
            <div
                className={`game-setting game-setting-auto${
                    isTutorial ? ' z-index-tutorial' : ''
                }`}
            >
                <input
                    id="auto-increment-checkbox"
                    type="checkbox"
                    onChange={(e) => {
                        setGameSettings({
                            type: 'autoIncrementalLevels',
                            value: e.target.checked,
                        });
                        setAnySettingsChanged(true);
                    }}
                    checked={gameSettings.autoIncrementalLevels}
                />

                <label htmlFor="auto-increment-checkbox">
                    {translate[locale]?.settings_auto_increment}
                </label>

                <div
                    className={`game-setting-mode ${
                        isTutorial ? 'z-index-tutorial' : ''
                    }`}
                >
                    <input
                        id="auto-next-level-checkbox"
                        type="checkbox"
                        onChange={(e) => {
                            setGameSettings({
                                type: 'autoMoveToNextGame',
                                value: e.target.checked,
                            });
                            setAnySettingsChanged(true);
                        }}
                        checked={gameSettings.autoMoveToNextGame}
                    />
                    <label htmlFor="auto-next-level-checkbox">
                        {translate[locale]?.settings_auto_move_to_next_game}
                    </label>
                    {isTutorial && tutorialStep === 8 && (
                        <TutorialGameSettingsNextGame />
                    )}
                </div>

                {isTutorial && tutorialStep === 7 && (
                    <TutorialGameSettingsSequenceLength />
                )}
            </div>

            <div className="game-setting game-setting-level-sequence-length">
                <div className="game-setting-level-sequence-length-label">
                    <label htmlFor="game-setting-level">
                        {translate[locale]?.settings_length_of_sequence}
                    </label>
                </div>
                <div className="game-setting-level-sequence-length-input">
                    <input
                        className={
                            gameSettings.isErrorInputLevel
                                ? 'game-settings-input-error'
                                : ''
                        }
                        type="text"
                        name="game-setting-level"
                        id="game-setting-level"
                        value={gameSettings.currentLevel}
                        size={4}
                        onChange={(e) => {
                            setGameSettings({
                                type: 'gameStartingLevel',
                                value: e.target.value,
                            });
                            setAnySettingsChanged(true);
                        }}
                    />
                </div>
                {gameSettings.isErrorInputLevel && (
                    <Typography
                        variant="caption"
                        gutterBottom
                        component="p"
                        className="error-caption"
                    >
                        {translate[locale]?.settings_enter_positive_number}
                    </Typography>
                )}
            </div>

            <div
                className={`game-setting game-setting-game-time ${
                    isTutorial ? 'z-index-tutorial' : ''
                }`}
            >
                <div className="game-setting-game-time-checkbox">
                    <input
                        id="apply-time-limit-checkbox"
                        type="checkbox"
                        onChange={(e) => {
                            setGameSettings({
                                type: 'applyGameTimeLimit',
                                value: e.target.checked,
                            });
                            setAnySettingsChanged(true);
                        }}
                        checked={gameSettings.applyGameTimeLimit}
                    />
                    <label htmlFor="apply-time-limit">
                        {translate[locale]?.settings_apply_time_limit}
                    </label>
                </div>
                <div className="game-setting-game-time-value">
                    <input
                        className={
                            gameSettings.isErrorInputGameTime
                                ? 'game-settings-input-error'
                                : ''
                        }
                        type="text"
                        name="time-limit"
                        id="game-setting-game-time"
                        disabled={!gameSettings.applyGameTimeLimit}
                        value={gameSettings.gameTimeLimit}
                        onChange={(e) => {
                            setGameSettings({
                                type: 'gameTimeLimit',
                                value: e.target.value,
                            });
                            setAnySettingsChanged(true);
                        }}
                        size={4}
                    />
                </div>
                {gameSettings.isErrorInputGameTime && (
                    <Typography
                        variant="caption"
                        gutterBottom
                        component="p"
                        className="error-caption"
                    >
                        {translate[locale]?.settings_enter_positive_number}
                    </Typography>
                )}
                {isTutorial && tutorialStep === 9 && (
                    <TutorialGameSettingsTimeLimit />
                )}
            </div>

            <div className="game-setting game-save-settings">
                <button
                    disabled={
                        !anySettingsChanged ||
                        gameSettings.isErrorInputGameTime ||
                        gameSettings.isErrorInputLevel
                    }
                    onClick={() => {
                        dispatch(actionSaveGameSettings({ ...gameSettings }));
                        setAnySettingsChanged(false);
                        setNewSettingsSavedShowMessage(true);
                        setTimeout(() => {
                            setNewSettingsSavedShowMessage(false);
                        }, 500);
                    }}
                    className={`button-game-save-settings ${
                        !anySettingsChanged ||
                        gameSettings.isErrorInputGameTime ||
                        gameSettings.isErrorInputLevel
                            ? 'button-pressed'
                            : ''
                    }`}
                    title={translate[locale]?.settings_save}
                >
                    <SaveIcon />
                </button>
                <button
                    className="button-game-close-settings"
                    onClick={() => {
                        dispatch(
                            actionToggleSettings({
                                isOpen: false,
                            })
                        );
                    }}
                    title={translate[locale]?.settings_close}
                >
                    <CloseIcon />
                </button>
                {newSettingsSavedShowMessage && (
                    <Typography
                        variant="caption"
                        gutterBottom
                        component="p"
                        className="game-save-settings-ok-message"
                    >
                        {translate[locale]?.settings_saved}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, { actionSaveGameSettings })(Settings);
