import HeaderMobileMenuButton from '../HeaderMobileMenuButton';
import HeaderWebsiteTitle from '../HeaderWebsiteTitle';
import ThemeSwitchNew from '../ThemeSwitchNew';

import Locale from '../Locale';

const Header = () => {
    return (
        <header className="header" data-cy="header">
            <HeaderMobileMenuButton />
            <HeaderWebsiteTitle />
            <Locale />
            <ThemeSwitchNew />
        </header>
    );
};

export default Header;
