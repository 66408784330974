import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionSkipAndNextNumber } from '../../redux/actions';
import * as constants from '../../redux/constants';
import { RootState } from '../../../redux/store';
import { TutorialButtonSkip } from '../Tutorials';
import { translate } from '../../i18n';
import Typography from '@material-ui/core/Typography';
import SkipNextIcon from '@material-ui/icons/SkipNext';

const ButtonSkip = () => {
    const dispatch = useDispatch();
    const isTutorial = useSelector(
        (state: RootState) => state.reducerM2.isTutorial
    );
    const tutorialStep = useSelector(
        (state: RootState) => state.reducerM2.tutorialStep
    );
    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );
    const isDisabled = useMemo(
        () =>
            gameStage !== constants.STAGE_03_USER_INPUT_OPEN &&
            gameStage !== constants.STAGE_02_NUMBER_FROM_COMPUTER_CLOSED,
        [gameStage]
    );
    const locale: any = useSelector(
        (state: RootState) => state.reducerApp.locale
    );

    return (
        <div className="skip-number">
            <button
                onClick={() => {
                    dispatch(actionSkipAndNextNumber());
                }}
                className={`skip-number-button${
                    isDisabled ? ' game-element-not-active' : ''
                }`}
                disabled={isDisabled}
            >
                <SkipNextIcon />

                <Typography variant="button" display="block" gutterBottom>
                    {translate[locale]?.skip}
                </Typography>
            </button>
            {isTutorial && tutorialStep === 3 && <TutorialButtonSkip />}
        </div>
    );
};

export default ButtonSkip;
