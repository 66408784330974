import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionSingleCorrectInput } from '../../redux/actions';
import { RootState } from '../../../redux/store';
import * as constants from '../../redux/constants';
type Props = {
    inputRef: any;
    boxIndex: number;
    userInputStepBackward: any;
    userInputStepForward: any;
    userManuallyFocusedOnBox: any;
};

const NumberUserInputBox = ({
    inputRef,
    boxIndex,
    userInputStepBackward,
    userInputStepForward,
    userManuallyFocusedOnBox,
}: Props) => {
    const handleKeyDown = (e: any) => {
        console.log('xxxxxxx e.key, e.code, e.which: ', e.key, e.code, e.which);
        if (
            e.key === 'Backspace' ||
            e.key === '67' ||
            e.which === '8'
            // && e.code !== 'Digit8' && e.code !== 'Numpad8'
        ) {
            if (userEntry) setUserEntry('');
            else userInputStepBackward();
        } else if (
            e.key === 'e' ||
            e.key === '-' ||
            e.key === '+' ||
            e.key === '.'
        ) {
            e.preventDefault();
            setUserEntry('');
            return false;
        } else if (e.key === 'ArrowLeft') {
            userInputStepBackward();
        } else if (e.key === 'ArrowRight') {
            userInputStepForward();
        }
    };

    const dispatch = useDispatch();
    const numberFromComputer = useSelector(
        (state: RootState) => state.reducerM2.numberFromComputer
    );
    const isPlaying = useSelector(
        (state: RootState) => state.reducerM2.isPlaying
    );
    const isCorrect = useSelector(
        (state: RootState) => state.reducerM2.isCorrect
    );
    const gamesPlayed = useSelector(
        (state: RootState) => state.reducerM2.gamesPlayed
    );
    const gameStage = useSelector(
        (state: RootState) => state.reducerM2.gameStage
    );
    const isSkipped = useSelector(
        (state: RootState) => state.reducerM2.isSkipped
    );

    const [userEntry, setUserEntry] = useState('');
    /** check single correct input */
    useEffect(() => {
        // if (isPlaying && userEntry.length > 0)
        if (isPlaying)
            dispatch(
                actionSingleCorrectInput({
                    correctInputIndex: boxIndex,
                    isCorrect: userEntry === numberFromComputer[boxIndex],
                })
            );
    }, [userEntry, boxIndex, dispatch, isPlaying, numberFromComputer]);

    useEffect(() => {
        setUserEntry('');
    }, [gamesPlayed]);

    return (
        <div className="user-input-box-wrapper">
            <input
                ref={inputRef}
                onKeyDown={handleKeyDown}
                type="number"
                className={`single-digit-box new-user-input-box${
                    isCorrect ? ' correct-input' : ''
                }${isSkipped ? ' skipped-input' : ''}`}
                value={userEntry}
                onChange={(e: any) => {
                    e.preventDefault();
                    const userInput: number = e.target.value;
                    if (e.target.value.match(/\d{1}/) && userEntry === '') {
                        setUserEntry(e.target.value);
                        userInputStepForward();
                    } else if (userInput % 10 === parseInt(userEntry)) {
                        userInputStepForward();
                    }
                }}
                onFocus={() => {
                    userManuallyFocusedOnBox(boxIndex);
                }}
                disabled={gameStage !== constants.STAGE_03_USER_INPUT_OPEN}
            />
        </div>
    );
};

export default NumberUserInputBox;
