import * as constants from '../constants';

const checkInputWithTarget = (userInput: any, numberFromComputer: any) => {
    return JSON.stringify(userInput) === JSON.stringify(numberFromComputer);
};

interface MiddlewareMethods {
    dispatch: any;
    getState: any;
}

export const middlewareM2 = ({ dispatch, getState }: MiddlewareMethods) => {
    return (next: any) => (action: any) => {
        next(action);
        switch (action.type) {
            case constants.START_PLAYING:
                dispatch({
                    type: constants.FLAG_GAME_NEW,
                });
                return;

            case constants.USER_TYPED_SOMETHING:
                if (
                    checkInputWithTarget(
                        action.userInput.match(/[0-9a-zA-Z]/g),
                        getState().reducerM2.numberFromComputer
                    )
                ) {
                    dispatch({
                        type: constants.FLAG_USER_INPUT_IS_CORRECT,
                    });
                }
                return;
            case constants.FLAG_USER_INPUT_IS_CORRECT:
                dispatch({
                    type: constants.TOGGLE_NUMBER_FROM_COMPUTER,
                    isOpen: true,
                });
                if (
                    !getState().reducerM2.isTutorial &&
                    getState().reducerM2.autoMoveToNextGame
                ) {
                    setTimeout(() => {
                        dispatch({
                            type: constants.FLAG_GAME_END,
                        });
                        dispatch({
                            type: constants.FLAG_GAME_NEW,
                        });
                    }, 500);
                }
                return;
            case constants.TIMEOUT_STAGE_01_NUMBER_FROM_COMPUTER_OPEN:
                dispatch({
                    type: constants.USER_PRESS_CLOSE_NUMBER_FROM_COMPUTER,
                });
                return;
            case constants.TIMEOUT_STAGE_02_NUMBER_FROM_COMPUTER_CLOSED:
                dispatch({
                    type: constants.USER_PRESS_OPEN_INPUT_BUTTON,
                });
                return;
            case constants.TIMEOUT_STAGE_03_USER_INPUT_OPEN:
                dispatch({
                    type: constants.USER_PRESS_SKIP_NUMBER,
                });
                return;

            case constants.TIMEOUT_STAGE_04_COMPARE_RESULT_AND_FINISH_GAME:
            case constants.USER_PRESS_NEXT_NUMBER_BUTTON:
                setTimeout(() => {
                    dispatch({
                        type: constants.FLAG_GAME_END,
                    });
                    dispatch({
                        type: constants.FLAG_GAME_NEW,
                    });
                }, 500);

                return;
            case constants.SETTINGS_SAVE:
                localStorage.setItem('gameSettings', JSON.stringify(action));
                return;
            case constants.FINISH_PLAYING:
                const settingsFromLocalStorage =
                    localStorage.getItem('gameSettings');
                if (settingsFromLocalStorage) {
                    const _settingsFromLocalStorage = JSON.parse(
                        settingsFromLocalStorage
                    );
                    dispatch({
                        type: constants.SETTINGS_SAVE,
                        applyGameTimeLimit:
                            _settingsFromLocalStorage.autoMoveToNextGame,
                        autoIncrementalLevels:
                            _settingsFromLocalStorage.autoIncrementalLevels,
                        autoMoveToNextGame:
                            _settingsFromLocalStorage.autoMoveToNextGame,
                        currentLevel: _settingsFromLocalStorage.currentLevel,
                        gameTimeLimit: _settingsFromLocalStorage.gameTimeLimit,
                    });
                }
                return;
            default:
                return;
        }
    };
};
