import * as constants from '../constants';

const actionStartPlaying = () => {
    return {
        type: constants.START_PLAYING,
    };
};

const actionFinishPlaying = () => {
    return {
        type: constants.FINISH_PLAYING,
    };
};

const actionOpenNumber = () => {
    return {
        type: constants.USER_PRESS_OPEN_INPUT_BUTTON,
    };
};

const actionCloseNumberFromComputer = () => {
    return { type: constants.USER_PRESS_CLOSE_NUMBER_FROM_COMPUTER };
};

const actionSkipAndNextNumber = () => {
    return {
        type: constants.USER_PRESS_SKIP_NUMBER,
    };
};

const actionUserTypedSomething = (payload: any) => {
    return {
        type: constants.USER_TYPED_SOMETHING,
        userInput: payload.userInput,
    };
};

const actionUserPressesNextNumberButton = () => {
    return {
        type: constants.USER_PRESS_NEXT_NUMBER_BUTTON,
    };
};

const actionSaveGameSettings = (payload: any) => {
    return {
        type: constants.SETTINGS_SAVE,
        applyGameTimeLimit: payload.applyGameTimeLimit,
        autoIncrementalLevels: payload.autoIncrementalLevels,
        autoMoveToNextGame: payload.autoMoveToNextGame,
        currentLevel: payload.currentLevel,
        gameTimeLimit: payload.gameTimeLimit,
    };
};

const actionTutorial = () => {
    return {
        type: constants.USER_PRESS_HOW_TO_PLAY,
    };
};

const actionTutorialNext = () => {
    return {
        type: constants.TUTORIAL_NEXT_STEP,
    };
};

const actionTutorialPrevious = () => {
    return {
        type: constants.TUTORIAL_PREVIOUS_STEP,
    };
};

const actionToggleSettings = (payload: any) => {
    return {
        type: constants.SETTINGS_TOGGLE_MENU,
        isOpen: payload.isOpen,
    };
};

const actionToggleHowToPlay = (payload: any) => {
    return {
        type: constants.TOGGLE_HOW_TO_PLAY,
        isOpen: payload.isOpen,
    };
};

const actionPause = (payload: any) => {
    return {
        type: constants.PAUSE_BUTTON_PRESSED,
        isPaused: payload.isPaused,
    };
};

const actionSingleCorrectInput = (payload: any) => {
    return {
        type: constants.SINGLE_CORRECT_INPUT,
        correctInputIndex: payload.correctInputIndex,
        isCorrect: payload.isCorrect,
    };
};

const actionUserTotalInputCorrect = () => {
    return {
        type: constants.FLAG_USER_INPUT_IS_CORRECT,
    };
};

export {
    actionTutorial,
    actionStartPlaying,
    actionFinishPlaying,
    actionOpenNumber,
    actionUserTypedSomething,
    actionSkipAndNextNumber,
    actionTutorialPrevious,
    actionTutorialNext,
    actionToggleSettings,
    actionSaveGameSettings,
    actionUserPressesNextNumberButton,
    actionCloseNumberFromComputer,
    actionPause,
    actionSingleCorrectInput,
    actionUserTotalInputCorrect,
    actionToggleHowToPlay,
};
